@import 'lib/styles/variables';

.card {
    position: relative;
    display: block;
    width: 100%;
    height: 540px;
    cursor: pointer;
    perspective: 1000px;

    &.active {
        .front {
            transform: rotateY(180deg);
        }

        .back {
            transform: rotateY(0deg);
        }
    }

    .front,
    .back {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $white;
        }

        position: absolute;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 2.5rem 2.8125rem;
        backface-visibility: hidden;
        background-position: center;
        background-size: cover;
        color: #fff;
        transform-style: preserve-3d;
        transition: ease-in-out 600ms;

        p {
            max-width: 350px;
            font-size: 1.25rem;
            line-height: 1.3;
        }

        img {
            max-width: 3.75rem;
            margin-top: auto;
        }
    }

    .front {
        h3 {
            margin-bottom: 1.125rem;
            font-size: 1.625rem;
            line-height: 1;
        }
    }

    .back {
        border: 1px solid $black;
        background: #fff;
        transform: rotateY(-180deg);
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .front {
            padding: 4.0625rem 20%;

            h3 {
                margin-bottom: 1.25rem;
                font-size: 2.5rem;
            }

            p {
                font-size: 1.875rem;
                line-height: 1.27;
            }
        }

        .back {
            padding: 4.375rem 20%;
        }
    }
}

.faqsItem {
    border-top: 1px solid $black;

    &.active {
        p {
            color: $black;
        }

        .question {
            svg {
                transform: rotate(-180deg);
            }
        }

        .answer {
            max-height: 2000px;
        }
    }

    .question {
        p {
            font-size: 1.375rem;
        }

        svg {
            margin-right: 0.5rem;
            fill: currentcolor;
            transition: transform 0.3s;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .answer {
        overflow: hidden;
        max-height: 0;

        p {
            max-width: 95%;
            padding-top: 1.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .question {
            p {
                font-size: 1.5rem;
            }
        }

        .answer {
            p {
                font-size: 1rem;
            }
        }
    }
}
