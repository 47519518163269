@import 'lib/styles/variables';

.horizontalScroll {
    display: grid;
    justify-content: start;
    margin: 0 auto;
    gap: 1rem;
    grid-auto-flow: column;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }

    &::after {
        display: block;
        content: '';
    }

    > * {
        scroll-snap-align: center;

        &:first-child {
            margin-right: 1rem;
            margin-left: 1rem;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            &:first-child {
                margin-left: 2.5rem;
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
