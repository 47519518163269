@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.basic {
    > .body {
        max-width: 370px;
        padding: 0 20px;
        margin: auto;
        text-align: center;

        > h1 {
            font-size: 48px;
            line-height: 1.17;
        }

        > p {
            &:not(.heading) {
                font-family: $calibre-medium;
                font-size: 20px;
                line-height: 1.3;
            }

            &.heading {
                u {
                    position: relative;
                    display: inline-block;
                    text-decoration: none;

                    &::after {
                        position: absolute;
                        z-index: -1;
                        right: 0;
                        bottom: 6px;
                        left: 0;
                        height: 6px;
                        background-image: linear-gradient(to right, #009E82 0%, #fff 100%);
                        content: '';
                    }
                }

                + :not(.heading) {
                    margin-top: 40px;
                }
            }

            &.heading-2 {
                font-size: 30px;
                letter-spacing: -0.38px;
                line-height: 1.2;

                u {
                    &::after {
                        bottom: 10px;
                    }
                }
            }

            &.heading-3 {
                font-size: 2.125rem;
                line-height: 1.29;

                + :not(.heading) {
                    margin-top: 1.25rem;
                    font-family: $calibre;
                    font-size: 1.125rem;

                    a {
                        color: $blue;
                        font-family: $calibre-medium;
                        text-decoration: underline;
                    }
                }
            }

            &.heading-4 {
                font-size: 1.875rem;
                line-height: 1.13;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            > p {
                &:not(.heading) {
                    font-size: 26px;
                    line-height: 1.23;
                }

                &.heading {
                    u {
                        &::after {
                            height: 10px;
                        }
                    }
                }

                &.heading-2 {
                    font-size: 48px;
                    letter-spacing: -0.6px;
                    line-height: 1.17;

                    u {
                        &::after {
                            bottom: 12px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            max-width: none;
            margin: 0;
            text-align: left;
        }
    }

    .carousel {
        margin: 0;

        :global {
            .carousel {
                .dot {
                    background: $gray-dark;

                    &.selected {
                        background: $secondary-dark;
                    }
                }
            }
        }
    }
}

.single {
    position: relative;

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            width: 630px;
            max-width: none;
            padding: 0;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            width: 850px;
            margin: auto;
            text-align: center;
        }
    }
}

.single--split {
    position: relative;
    display: none;

    @media only screen and (min-width: $breakpoint-lg) {
        display: block;

        > .body {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: flex;
            width: 50%;
            flex-direction: column;
            justify-content: center;
            padding: 0 115px;

            p {
                color: $white;

                &.heading-2 {
                    width: 90%;
                }

                &:not(.heading) {
                    letter-spacing: -0.6px;
                }
            }
        }
    }
}

.single--bg {
    position: relative;

    > .body {
        @extend %absolute-center;

        color: $white;

        p {
            &.heading-2 {
                font-size: 1.875rem;

                + p {
                    &:not(.heading) {
                        margin-top: 1.875rem;
                        font-size: 1rem;
                        line-height: 1.38;
                    }
                }
            }

            &:last-of-type {
                @include viomeButton('secondary-dark');

                margin-top: 2.8125rem;
            }
        }
    }

    img {
        width: 100%;
        height: 448px;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            max-width: none;
        }

        img {
            width: 100%;
            height: 600px;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            padding: 0;
            text-align: center;
        }
    }
}

.twoColumn, .twoColumn--large {
    display: flex;
    align-items: center;
    justify-content: center;

    > .section, > .body {
        + .section {
            margin-top: 130px;
        }
    }

    .carousel {
        max-width: 400px;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            max-width: 410px;
            padding: 0;
        }
    }
}

.twoColumn {
    &--large {
        flex-direction: column;

        @media only screen and (min-width: $breakpoint-lg) {
            flex-direction: row;

            > .section, > .body, > picture {
                width: 50%;
                flex: 0 0 50%;

                + .section, + picture {
                    margin: 0 0 0 130px;
                }
            }
        }
    }
}

.tiempos {
    > .body {
        h1, .heading-1, .heading-2, .heading-3 {
            font-family: $tiempos;
            font-weight: normal;
        }
    }
}

.bodyPadding {
    > .body {
        padding: 90px 20px;
        text-align: left;

        p {
            &:not(.heading) {
                font-size: 16px;
                line-height: 1.38;
            }
        }
    }

    &.twoColumn--large {
        @media only screen and (min-width: $breakpoint-tablet) {
            > .body {
                max-width: 768px;
                padding: 120px 130px;
            }

            > picture {
                margin: 0 !important;
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            align-items: stretch;

            > .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;

                p {
                    max-width: 505px;
                }
            }

            > picture {
                img {
                    width: 100%;
                }
            }
        }
    }
}
