@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.basic {
    padding: 0 1.875rem 3.75rem;
    margin: auto;

    .heading-4, p {
        margin-bottom: 1.875rem;
    }

    &--single {
        max-width: 568px;

        picture {
            img {
                width: 100%;
            }
        }

        p {
            &.heading-3 {
                max-width: 300px;
                margin: 0 auto 1.125rem;
                font-size: 1.875rem;
            }
        }

        .testimonial-carousel {
            margin-top: 3.125rem;

            :global {
                .carousel {
                    .slider-wrapper {
                        padding-bottom: 3.125rem;
                    }

                    .control-dots {
                        .dot {
                            background: $gray-dark;

                            &.selected, &.hover {
                                background: $secondary;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            max-width: 728px;
        }
    }

    &--two {
        @media only screen and (min-width: $breakpoint-lg) {
            display: flex;
            align-items: center;

            &.full {
                padding: 12.5rem calc(165 / 1440 * 100%);

                > .body {
                    margin: 0 calc(116 / 1440 * 100%) 0 0;
                }
            }
        }
    }

    &--center {
        p, .animate {
            text-align: center;
        }
    }

    &--border-bottom {
        padding-bottom: 8.125rem;
        border-bottom: solid 1px $gray-dark;
        margin-bottom: 8.125rem;
    }

    &--rotation {
        max-width: 370px;
        padding: 6.25rem 2.1875rem;
        border-top: solid 1px $gray-dark;
        margin: 5.625rem auto 0;

        .animate {
            margin-bottom: 0;
            color: $secondary-dark;
            font-family: $tiempos;
            font-size: 1.875rem;
            font-weight: normal;
            line-height: 1.4;
            text-align: center;

            :global {
                .text-transition_inner {
                    > div {
                        width: 100%;
                    }
                }
            }
        }

        .body {
            overflow: hidden;
            height: 200px;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 652px;
            padding: 6.25rem 0 3.125rem;

            .body {
                width: 470px;
                height: 150px;
                margin: auto;
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            max-width: 1110px;
            padding: 6.25rem 0 1.875rem;

            .body {
                width: 100%;
                padding: 0 9.375rem;
            }
        }
    }

    &--wrap {
        width: 100%;
        max-width: 370px;
        padding: 6.25rem 0 2.5rem;
        margin: auto;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                display: flex;
                flex: 0 0 50%;
                flex-direction: column;
                align-items: center;
                margin-bottom: 3.75rem;
            }

            p {
                font-family: $calibre-semibold;
                font-size: 1.125rem;
                line-height: 1;
            }

            .image {
                margin: 0 auto 0.625rem;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 728px;
            padding: 5.625rem 0 10.625rem;

            ul {
                flex-wrap: nowrap;

                li {
                    flex-basis: 25%;
                    margin: 0;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            padding-top: 9.375rem;
        }
    }

    &--probiotics, &--prebiotics {
        &.full {
            padding: 6.25rem 1.25rem;

            > .body {
                max-width: 450px;
                margin: auto;

                > p {
                    &:not(.heading) {
                        margin-bottom: 1.25rem;
                        font-size: 1.125rem;
                        line-height: calc(24 / 18);
                    }

                    &.heading-3 {
                        margin-bottom: 0.625rem;
                        color: $secondary;
                        font-size: 1.875rem;

                        + p {
                            margin-bottom: 3.125rem;
                        }
                    }
                }

                + picture {
                    margin-top: 3.125rem;
                }

                ul {
                    margin-bottom: 3.125rem;
                }

                li {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 0.75rem;
                    margin-bottom: 0.75rem;

                    &::before {
                        display: block;
                        margin: 0.1875rem .75rem 0 0;
                        content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
                    }

                    &:last-of-type {
                        padding: 0;
                        margin: 0;
                    }

                    p {
                        margin-bottom: 0;
                        font-family: $calibre-semibold;
                        font-size: 1.25rem;
                        line-height: calc(28 / 20);
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .body {
                + picture {
                    margin-top: 4.0625rem;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &.full {
                padding: 12.5rem calc(165 / 1440 * 100%);
            }

            .body {
                + picture {
                    margin-top: 0;
                }
            }
        }
    }

    &--prebiotics {
        &.full {
            padding-top: 0;
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &.full {
                padding-top: 0;
            }
        }
    }

    &--probiotics {
        @media only screen and (min-width: $breakpoint-lg) {
            flex-direction: row-reverse;

            &.full {
                > .body {
                    margin: 0 0 0 calc(116 / 1440 * 100%);
                }
            }
        }
    }

    &--gi {
        &-one, &-two {
            > .body {
                margin-bottom: 5rem;
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                width: 73%;
                padding: 0 0 3.75rem;
                margin: auto;

                p {
                    text-align: left;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                width: 100%;

            }
        }

        &-one {
            &.full {
                padding-bottom: 11.875rem;
            }

            @media only screen and (max-width: $breakpoint-tablet) {
                p {
                    padding: 0 1.875rem;
                }

                picture {
                    justify-content: flex-end;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                > .body {
                    max-width: 520px;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                &.full {
                    > .body {
                        margin-right: 6.25rem;
                    }
                }
            }
        }

        &-two {
            @media only screen and (min-width: $breakpoint-tablet) {
                > .body {
                    max-width: 430px;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                max-width: 940px;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding-bottom: 10.9375rem;
            }
        }
    }

    &--gi-plan {
        &-one, &-two, &-three {
            &.full {
                padding: 7.5rem 0 8.4375rem;

                > .body {
                    width: 100%;
                    max-width: 370px;
                    padding: 0 1.25rem;
                    margin: auto;
                }
            }

            .heading-3 {
                font-size: 1.875rem;
                line-height: calc(34 / 30);
            }

            p, u {
                font-size: 1.125rem;
                line-height: calc(24 / 18);
            }

            p {
                &:last-of-type {
                    margin: 0;
                }
            }
        }

        &-one {
            picture {
                margin-top: 3.4375rem;
            }
        }

        &-two {
            @include viomeButton('secondary', transparent);

            a {
                width: 100%;
                max-width: 275px;
                margin: 3.125rem auto 0;
            }

            picture {
                margin-bottom: 2.5rem;
            }
        }

        &-three {
            picture {
                margin-bottom: 5rem;
            }

            b {
                font-family: $tiempos-semibold !important;
                font-size: 1.3125rem;
                line-height: calc(24 / 21);
            }

            u {
                color: $red;
                font-family: $tiempos;
                text-decoration: none;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            &-one, &-two, &-three {
                &.full {
                    > .body {
                        max-width: 450px;
                        padding: 0;
                    }
                }
            }

            &-three {
                img {
                    width: 540px;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &-one, &-two, &-three {
                width: 100%;
                max-width: 1110px;
                align-items: center;
                justify-content: center;
                margin: auto;

                &.full {
                    > .body {
                        flex-shrink: 0;
                        margin: 0;
                    }
                }
            }

            &-one {
                &.full {
                    padding: 4.375rem 0;
                }

                picture {
                    margin: 0 0 0 calc(200 / 1440 * 100%);
                }
            }

            &-two, &-three {
                &.full {
                    padding: 10.9375rem 0;
                }

                picture {
                    margin: 0 calc(200 / 1440 * 100%) 0 0;
                }
            }
        }
    }

    &--test {
        padding: 0 20px;

        > .body {
            p {
                @include viomeButton;

                margin-top: 120px;

                text-align: center;
            }
        }

        .grid {
            display: none;
        }

        :global {
            .carousel {
                max-width: 373px;

                .control-dots {
                    .dot {
                        background: $gray-dark !important;

                        &.selected {
                            background: $secondary-dark !important;
                        }
                    }
                }

                .wwhw {
                    .heading-3 {
                        margin: 0;
                        font-size: 26px;

                        u {
                            -webkit-background-clip: text;
                            background-image: linear-gradient(to right, #ca3550, #7348b7 45%, #009e82 90%);
                            -webkit-box-decoration-break: clone;
                            -webkit-text-fill-color: transparent;
                            text-transform: uppercase;
                        }
                    }

                    picture {
                        margin-top: -40px;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .carousel {
                display: none;
            }

            .grid {
                display: block;
                max-width: 748px;

                .grid__wrapper {
                    display: flex;
                    justify-content: center;
                }

                .wrap {
                    flex-wrap: wrap;
                }

                .grid__item {
                    flex: 0 0 374px;

                    > .body {
                        .heading-3 {
                            margin: 0;
                            font-size: 26px;
                            text-align: center;

                            u {
                                -webkit-background-clip: text;
                                background-image: linear-gradient(to right, #ca3550, #7348b7 45%, #009e82 90%);
                                -webkit-box-decoration-break: clone;
                                -webkit-text-fill-color: transparent;
                                text-transform: uppercase;
                            }
                        }
                    }

                    > picture {
                        margin-top: -30px;
                    }

                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &.reverse {
                flex-direction: column-reverse;
            }
        }

        @media only screen and (min-width: $breakpoint-xl) {
            .grid {
                .wrap {
                    flex-wrap: nowrap;
                }
            }
        }
    }
}
