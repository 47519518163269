@import 'lib/styles/variables';

.productSelector {
    svg {
        margin-right: 1.125rem;
    }

    .heading {
        margin-top: 1rem;
        color: $brand-green-3;
        font-size: 0.875rem;
        font-weight: 600;
    }

    .ageRestriction {
        font-size: 14px;
        line-height: 1.5;
    }

    .cta {
        min-height: 111px;

        :global {
            .CTA__Wrapper {
                display: flex;
                flex-direction: column;
            }

            .cta {
                margin: 0.5rem 0 0;
            }

            .salesMessage {
                order: 2;
                margin-bottom: 0;
            }

            .priceWrapper {
                .price {
                    font-size: 2.25rem;
                    line-height: 1.4;
                }

                .price--original {
                    font-size: 90%;
                }

                .price--current {
                    color: $brand-green-3;
                    font-weight: 600;

                    .suffix {
                        font-size: 50%;
                    }

                    .currency {
                        font-size: 40%;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.option {
    display: flex;
    padding: 1rem;
    border: 1px solid #aac7c7;
    cursor: default;
    transition: 0.2s linear;

    p {
        font-size: 1.125rem;
    }

    li {
        font-size: 0.875rem;
    }

    &[role='button'] {
        cursor: pointer;
    }

    .addOnPrice {
        color: $brand-green-3;
        font-size: 1rem;

        span {
            font-weight: 600;
        }
    }

    &.selected {
        svg {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            padding-top: 0.125rem;
            margin-top: 0.125rem;
        }
    }

    &.selected,
    &.active {
        border-color: #aac7c7;
        background-color: $pastel-green;
    }

    &.active {
        svg {
            min-width: 16px;
        }
    }

    &.optional {
        border-color: $black;
    }
}
