@import 'lib/styles/variables';

.faqs {
    padding-top: 2.8125rem;
    padding-bottom: 6.375rem;

    .body {
        width: 100%;
        max-width: 370px;
        margin: auto;
    }

    .heading-2 {
        margin-bottom: 1.25rem;
        color: $secondary-dark;
        font-size: 1.875rem;
    }

    hr {
        height: 3px;
        margin: 0 0 0.3125rem;
        background-color: $secondary;
    }

    .inline {
        border-bottom: 1px solid $gray;

        :global {
            .Card {
                .active {
                    .heading-2 {
                        font-weight: 600;
                    }
                }

                .heading-2 {
                    padding: 0.875rem 0 0.375rem;
                    font-family: $calibre;
                    font-size: 1.125rem;
                    font-weight: 400;
                }

                a {
                    color: $blue;
                }

                p {
                    &:not(.heading) {
                        padding: 0.75rem 0;
                        font-size: 1rem;
                        line-height: calc(20 / 16);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .body {
            max-width: 450px;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        padding-top: 3.25rem;

        .body {
            max-width: 728px;
        }
    }
}
