@import 'lib/styles/variables';

.person {
    &.default {
        display: flex;
        align-items: center;
        gap: 1rem;

        .name {
            font-size: 1.375rem;
            line-height: 1.4;
        }

        .role {
            color: $gray-3;
            font-size: 0.875rem;
        }

        :global {
            .image {
                margin: 0;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .name {
                font-size: 1.25rem;
            }

            .role {
                font-size: 1rem;
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            padding: 1rem;
            gap: 1.5rem;

            .name {
                font-size: 1.5rem;
            }
        }
    }
}
