@import 'lib/styles/variables';

.video {
    position: relative;
    width: 100%;

    &.testimonial {
        margin: auto;
        background-color: black;

        .text__wrapper {
            bottom: 32px;

            &.playing {
                display: none;
            }
        }

        .text__wrapper,
        .video__button {
            position: absolute;
            right: 16px;
            left: 16px;
        }

        .video__button {
            top: 32px;
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            .text__wrapper {
                bottom: 40px;
            }

            .text__wrapper,
            .video__button {
                position: absolute;
                right: 24px;
                left: 24px;
            }

            .video__button {
                top: 40px;
            }
        }

        @media only screen and (min-width: $breakpoint-xl) {
            .video__button {
                position: relative;
            }
        }
    }

    &__header {
        width: 100%;
        margin: 0 auto 2.8125rem;
        text-align: center;

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 570px;
            margin-bottom: 3.75rem;
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            max-width: 610px;
        }

        @media only screen and (min-width: $breakpoint-xl-new) {
            max-width: 730px;
        }
    }

    &__wrapper {
        position: relative;

        .video__button {
            transition: all ease-in-out 0.2s;
        }

        &.tall {
            > div {
                height: 100%;
            }
        }

        &:hover {
            .video__button {
                transform: scale(1.1);

                :global() {
                    circle {
                        fill: $primary;
                        opacity: 1;
                    }
                }
            }
        }

        > div {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__player {
        position: absolute;
        top: 0;
        left: 0;
    }
}
