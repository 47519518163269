@import 'lib/styles/variables';

.vls {
    &__forms {
        text-align: left;

        label {
            display: inline-block;
            margin-top: 2.375rem;
            margin-bottom: 0.5rem;

            p {
                display: inline;
            }
        }

        input {
            border: 1px solid $gray-darker;
            margin: 0;

            &[type='text'] {
                width: 100%;
            }

            &[type='date'],
            &[type='email'],
            &[type='number'],
            &[type='text'],
            &[type='tel'] {
                padding: 0.625rem 1rem;

                &:focus {
                    font-family: $calibre-medium;
                }
            }
        }

        textarea {
            width: 100%;
            padding: 0.625rem 1rem;
            border: 1px solid $gray-darker;
            margin: 0;

            &:focus {
                font-family: $calibre-medium;
            }
        }

        ul {
            padding: 0;

            &.vls__forms--radio {
                margin: 1.25rem 0 0;

                li {
                    position: relative;
                    margin-right: 1.75em;
                    list-style-type: none;

                    + li {
                        margin-top: 1.25rem;
                    }

                    input[type='radio'] {
                        display: none;

                        &:checked ~ label .check {
                            background-color: $secondary-light;
                        }
                    }

                    label {
                        position: relative;
                        display: grid;
                        align-items: flex-start;
                        margin: 0;
                        cursor: pointer;
                        font-family: $calibre-medium;
                        grid-gap: 0.75rem;
                        grid-template-columns: 1.5625rem auto;
                        line-height: calc(26 / 20);
                    }

                    .check {
                        display: block;
                        width: 25px;
                        height: 25px;
                        border: 1px solid $gray-darkest;
                        border-radius: 100%;
                        background-color: $white;
                    }

                    &:hover .check {
                        position: relative;
                        border-width: 5px;
                        border-color: $white;
                        background-color: $secondary-light;

                        &::before {
                            position: absolute;
                            z-index: -1;
                            border: 1px solid $gray-darkest;
                            border-radius: 100%;
                            content: ' ';
                            inset: -6px;
                        }
                    }
                }
            }
        }

        &--pair {
            display: flex;
            justify-content: space-between;

            label {
                margin-top: 0;
            }
        }

        &--field {
            display: flex;
            width: calc(50% - 15px);
            flex-direction: column;

            &--full {
                width: 100%;
            }
        }

        &--submitted {
            input,
            textarea {
                &:invalid {
                    border: 1px solid $red;
                }
            }

            input {
                &[type='checkbox'],
                &[type='radio'] {
                    &:invalid {
                        & ~ label {
                            color: $red;

                            .check {
                                border-color: $red;
                            }
                        }
                    }
                }
            }
        }

        &--thankyou {
            display: none;

            &.active {
                display: block;
                margin: 5em 15% 10em;
            }

            h4 {
                margin-bottom: 1em;
            }
        }
    }
}
