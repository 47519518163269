@import './Basic.module.scss';

.viome-info {
    .body {
        > p {
            max-width: 610px;
            margin: auto;

            &:not(.heading) {
                font-size: 18px;
                line-height: 1.33;
            }

            + p {
                margin-top: 70px;
            }
        }

        > ol, > ul {
            display: flex;

            .inline {
                width: auto !important;

                .image {
                    margin: auto;
                }
            }
        }

        > ol {
            flex-direction: column;
            justify-content: center;
            margin-top: 30px;

            p {
                font-family: $calibre-semibold;
                font-size: 16px;
                line-height: 1.13;
            }

            .inline {
                margin: 16px 0;
            }
        }

        > ul {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 80px;

            > li {
                flex: 0 0 104px;

                + li {
                    margin-top: 70px;
                    margin-left: 88px;
                }

                &:nth-of-type(2) {
                    margin-top: 0;
                }

                &:nth-of-type(3) {
                    margin-left: 0;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        width: 678px;
        margin: auto;

        .body {
            width: auto;

            > p {
                &:not(.heading) {
                    font-size: 18px;
                    line-height: 1.33;
                }
            }

            > ol {
                flex-direction: row;
                align-items: flex-start;

                .inline {
                    margin: 0 28px;
                }
            }

            > ul {
                > li {
                    + li {
                        margin: 0;
                    }
                }
            }
        }
    }
}
