@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.landing-hero {
    position: relative;

    &.full {
        max-width: 370px;
        padding: 0 1.25rem;
        margin: 0 auto 12.1875rem;
    }

    .body {
        padding: 3.75rem 0;
        text-align: center;
    }

    .heading-1 {
        margin-bottom: 1rem;
        font-family: $tiempos;
        font-size: 3.125rem;
        font-weight: normal;
        line-height: 1.15;
    }

    p {
        font-size: 1.5rem;
        line-height: 1.25;
    }

    picture {
        &:nth-of-type(2) {
            @extend %absolute-x;

            bottom: -62.5px;
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &.full {
            max-width: none;
            padding: 0;
            margin-bottom: 15.625rem;
        }

        .heading-1 {
            width: 638px;
        }

        p {
            width: 560px;
        }

        p, h1 {
            margin-right: auto;
            margin-left: auto;
        }

        picture {
            width: 570px;
            margin: auto;

            &:nth-of-type(2) {
                bottom: -87px;
                width: 686px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        display: flex;

        &.full {
            margin: 0;
        }

        .body {
            padding-top: 9.375rem;
            margin-left: calc(165 / 1440 * 100%);
            text-align: left;
        }

        p, h1 {
            max-width: 550px;
            margin-left: 0;
        }

        picture {
            width: 605px;
            flex-shrink: 0;
            margin: 0;

            &:nth-of-type(2) {
                right: unset;
                bottom: 145px;
                left: calc(156 / 1440 * 100%);
                width: 777px;
                transform: none;
            }
        }
    }
}
