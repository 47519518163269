@import '../../lib/styles/variables';

.quote {
    .body {
        padding: 2.5rem;
        text-align: center;

        &::before {
            display: block;
            margin-bottom: 2.1875rem;
            content: url('https://images.ctfassets.net/qk4l4jfatr3e/5lRoCSYPsS7L738zMBRNKI/1a510b04c1b775d00c7c8257710d7f4c/invalid-name.svg');
        }
    }

    p {
        &.heading-2 {
            margin-bottom: 0.625rem;
            font-size: 1.875rem;
            line-height: 1.17;
        }

        &:not(.heading) {
            font-size: 1.25rem;
        }
    }

    &.hippocrates {
        max-width: 370px;
        margin: 3.125rem auto 8.75rem;

        .body {
            border-width: 0.1875rem;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: conic-gradient(from 0.42turn, $primary, $secondary-light 0.49turn, $primary);
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            width: 665px;
            max-width: none;
            padding: 0;

            .body {
                padding: 8.125rem 9.375rem;
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            width: 1110px;
        }
    }
}
