/* stylelint-disable at-rule-empty-line-before */
@import './variables';

// COMMON COMPONENTS
@mixin checklist(
    $scheme: 'primary',
    $color: $primary,
    $size: 1.125rem,
    $lh: calc(28 / 20),
    $border: 0,
    $hasPlus: false
) {
    display: flex;
    align-items: flex-start;
    color: $color;

    &::before {
        display: block;
        margin: 0 0.75rem 0 0;
        line-height: $lh;

        @if $scheme == 'thin' {
            margin-top: -0.125rem;
            content: url('https://images.ctfassets.net/qk4l4jfatr3e/2S2r5UjaKS8FR0hZIALqEU/336dc08722013735cdfd04c0ca13b009/stroke-copy-3.svg');
        } @else {
            content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
        }
    }

    &:last-of-type {
        padding: 0;
        margin: 0;

        @if $border != 0 {
            p {
                &:not(.heading) {
                    border: 0;
                }
            }
        }

        @if $hasPlus {
            @if $scheme == 'thin' {
                &::before {
                    content: url('https://images.ctfassets.net/qk4l4jfatr3e/4snpIyxjO7OHa6oaw8Tecd/c8234f8ace2a532a6fa64c6bd36c6610/group-13.svg');
                }
            } @else {
                &::before {
                    content: url('https://images.ctfassets.net/qk4l4jfatr3e/4WrqUuh3ZD29IAuKkXkrh1/6fca0b19052488f5458865c4acf222c3/green-plus-thick.svg');
                }
            }
        }
    }

    p {
        &:not(.heading) {
            font-size: $size;
            line-height: $lh;

            @if $border != 0 {
                border-bottom: solid 0.5px $border;
            }
        }
    }
}

@mixin dropdownSelect(
    $bg: $white,
    $color: $secondary-dark,
    $font: $calibre-semibold,
    $hover: rgba(0, 0, 0, 0.2)
) {
    position: relative;
    z-index: 1;
    width: 100px;
    height: 50px;
    flex-shrink: 0;
    border: 2px solid $color;
    background: $bg;

    .selected {
        display: flex;
        width: 100%;
        height: 46px;
        align-items: center;
        justify-content: center;

        svg {
            margin: 0 0 0.125rem 1.375rem;
            transition: transform 0.2s ease-in-out;
        }
    }

    .selected,
    li {
        color: $color;
        cursor: pointer;
        font-family: $font;
        font-size: 1.25rem;
        line-height: 1;
    }

    .active {
        svg {
            margin-bottom: 0.25rem;
            transform: rotate(180deg);
        }

        + ol {
            max-height: 2000px;
            visibility: visible;
        }
    }

    ol {
        position: absolute;
        right: -2px;
        left: -2px;
        overflow: hidden;
        max-height: 0;
        padding: 0;
        border: 2px solid $color;
        margin: 0;
        background: $bg;
        visibility: hidden;
    }

    li {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;

        &::before {
            display: none !important;
        }

        &:hover {
            background: $hover;
        }
    }
}

@mixin viomeButton(
    $scheme: 'default',
    $bg: $secondary-light,
    $color: $white,
    $hover: $secondary
) {
    a,
    button {
        @extend %viome-btn;

        @if $scheme == 'dark' {
            background: $white;
            color: $secondary;

            &:hover {
                background: $secondary-bright;
            }
        } @else if($scheme == 'secondary') {
            border: 2px solid $primary;
            background: $white;
            color: $primary;

            &:hover {
                border-color: $secondary;
                color: $secondary;
            }
        } @else if($scheme == 'secondary-dark') {
            border: 2px solid $white;
            background: transparent;
            color: $white;

            &:hover {
                border-color: $secondary-bright;
                color: $secondary-bright;
            }
        } @else if($scheme == 'black') {
            border: 0;
            background: $primary;
            color: $white;
        } @else {
            background: $bg;
            color: $color;

            &:hover {
                background: $hover;
            }
        }
    }
}

@mixin viomeArrow($direction: 'left', $color: $primary) {
    display: inline-block;
    padding: 0.25rem;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: $color;
    margin-right: 0.375rem;

    @if $direction == 'right' {
        transform: rotate(-45deg);
    } @else if($direction == 'up') {
        transform: rotate(-135deg);
    } @else if($direction == 'down') {
        transform: rotate(45deg);
    } @else {
        transform: rotate(135deg);
    }
}

@mixin viomeArrow($direction: 'left', $color: $primary) {
    display: inline-block;
    padding: 0.25rem;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: $color;
    margin-right: 0.375rem;

    @if $direction == 'right' {
        transform: rotate(-45deg);
    } @else if($direction == 'up') {
        transform: rotate(-135deg);
    } @else if($direction == 'down') {
        transform: rotate(45deg);
    } @else {
        transform: rotate(135deg);
    }
}

%font-overrides {
    &.weight {
        @for $i from 1 through 10 {
            &-#{$i * 100} {
                font-weight: $i * 100 !important;

                * {
                    font-weight: $i * 100;
                }

                i,
                b {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }
    }

    @each $font-size in $font-sizes {
        &.base\:#{$font-size} {
            font-size: #{$font-size}px !important;

            * {
                font-size: #{$font-size}px;
            }

            i,
            b {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        @each $font-size in $font-sizes {
            &.md\:#{$font-size} {
                font-size: #{$font-size}px !important;

                * {
                    font-size: #{$font-size}px;
                }

                i,
                b {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        @each $font-size in $font-sizes {
            &.lg\:#{$font-size} {
                font-size: #{$font-size}px !important;

                * {
                    font-size: #{$font-size}px;
                }

                i,
                b {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }
    }
}

%viome-btn {
    display: inline-flex;
    min-width: 90px;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    font-family: $calibre-semibold;
    font-size: 1.25rem;
    line-height: 1;
}

%standard-link {
    color: $blue;
    font-family: $calibre-medium;
    font-size: 1.25rem;
    line-height: 1;
}

// REUSABLE STYLES
%absolute-full {
    position: absolute;
    inset: 0;
}
%absolute-x {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
}
%absolute-y {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
}
%absolute-center {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
}
