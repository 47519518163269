@import 'lib/styles/variables';
@import 'lib/styles/common';

.wrapper {
    .desktop {
        display: none;
    }

    .headline {
        p {
            font-size: 1.75rem;
            line-height: 1.3;
        }
    }

    .product {
        padding: 1rem;
        border: 1px solid $gray-2;
        background-color: $white;

        .top {
            margin-top: 1rem;
        }

        .quantity {
            color: $gray-7;
        }
    }

    .upsell {
        background-color: $pastel-green;

        .message {
            color: $brand-green-3;
        }

        .additional {
            color: $brand-green-2 !important;
        }

        .additional,
        .include,
        .misc,
        .disclaimer {
            font-size: 0.875rem;
        }

        .ctas {
            :global {
                .CTA__Wrapper {
                    &:first-of-type {
                        .cta {
                            border: none;
                            background-color: $brand-green-3;
                        }
                    }

                    + .CTA__Wrapper {
                        margin-top: 1rem;
                    }
                }
            }
        }

        .details {
            ul {
                margin: 0;
            }

            li {
                display: flex;
                list-style: none;

                svg {
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-top: 0.375rem;
                }
            }

            .addons {
                li {
                    gap: 1rem;

                    + li {
                        margin-top: 0.5rem;
                    }
                }

                .name {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 600;
                }

                .misc {
                    color: $brand-green-3;
                }
            }

            .more {
                li {
                    gap: 1rem;
                }
            }
        }

        .disclaimer {
            border-top: 1px solid $gray-4;
            color: $gray-5;
        }
    }

    .product,
    .upsell {
        .name,
        .message {
            margin-bottom: 1rem;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.4;
        }
    }

    :global {
        .CTA__Wrapper {
            .cta {
                &.ForwardArrow {
                    flex-direction: column;
                    align-items: flex-end;

                    &:focus {
                        color: $black !important;
                    }
                }
            }
        }

        .price {
            color: $brand-green-3;
            font-size: 1.375rem;
            font-weight: 600;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .head {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
        }

        .product {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .description {
                flex: 0 0 100%;
                margin-top: 1rem;
                color: $gray-5;
            }

            .quantity {
                font-size: 1.125rem;
            }

            .top {
                max-width: calc(100% - 136px);
                flex-grow: 1;
                margin-top: 0;
            }

            :global {
                .image {
                    margin: 0;
                }

                .priceWrapper {
                    text-align: right;
                }
            }
        }

        .upsell {
            .additional,
            .includes,
            .misc {
                font-size: 1rem;
            }

            .details {
                .addons {
                    .name {
                        font-size: 1.125rem;
                    }
                }
            }

            .ctas {
                display: flex;
                gap: 1rem;

                &.mobile {
                    display: none;
                }

                :global {
                    .CTA__Wrapper {
                        flex-grow: 1;

                        + .CTA__Wrapper {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        .body {
            display: flex;
        }

        .desktop {
            display: block;

            :global {
                .CTA__Wrapper {
                    text-align: right;
                }
            }
        }

        .head {
            display: block;

            .mobile {
                display: none;
            }
        }

        .base,
        .upsell {
            flex: 0 0 50%;
        }

        .upsell {
            .message {
                margin-bottom: 1.5rem;
                line-height: 36px;
            }

            .ctas {
                :global {
                    .cta {
                        padding: 0;
                    }
                }
            }
        }
    }
}
