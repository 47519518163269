@import 'lib/styles/variables';

@mixin click-horizontal-scroll {
    display: grid;
    justify-content: start;
    padding: 0 0.5rem;
    margin: 0 auto;
    gap: 0.625rem;
    grid-auto-flow: column;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-y: hidden;
    overscroll-behavior-x: contain;

    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }

    > div {
        width: 184px;
        scroll-snap-align: center;

        + div {
            margin-top: 0;
        }
    }

    .selector {
        height: 136px;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.7656rem;
        background-color: $gray-3;
        color: $black;

        &.active,
        &.default {
            background-color: $brand-green-4;
            color: $white;

            .arrow {
                color: $white;
            }
        }

        .arrow {
            width: auto;
            height: auto;
            margin-left: -0.5rem;
            color: $font-gray;
            transform: rotate(90deg);

            &::before,
            &::after {
                display: none;
            }

            svg {
                display: block;
                margin-left: 0;
                fill: currentcolor;
            }
        }
    }
}

.click {
    .selectors {
        padding: 0 0.9375rem;

        > div {
            + div {
                margin-top: 0.9375rem;
            }
        }

        .selector {
            height: 60px;
            padding: 0 0.9375rem;
            background-color: $brand-dark;
            color: $white;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            @include click-horizontal-scroll();
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            > div {
                width: min(248px, calc((100vw - 196px) / 5));
            }

            .selector {
                height: 153px;
            }
        }
    }

    .selected {
        position: fixed;
        z-index: 1002;
        overflow: scroll;
        padding: 5rem 0.9375rem;
        background-color: $brand-green-4;
        inset: 0;

        &.default {
            display: none;
        }

        :global {
            .moduleImage {
                width: 185px;
                margin: 3.125rem auto;
            }

            li {
                + li {
                    margin-top: 0.5rem;
                }
            }

            h2 {
                margin-bottom: 0.5rem;
            }

            ul {
                margin-bottom: 3.125rem;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            position: relative;
            z-index: 999;
            margin-top: 0.375rem;

            &.default {
                display: block;
            }

            .icon {
                display: none;
            }

            h2 {
                width: 75%;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $white;
                margin-bottom: 6.25rem;
            }

            :global {
                .moduleImage {
                    position: absolute;
                    top: 0;
                    right: 45px;
                    width: 220px;
                    margin: 0;
                }

                .heading-4 {
                    width: 50%;
                    margin-bottom: 2.8125rem;
                }

                ul {
                    margin-bottom: 5.625rem;
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) and (max-width: $breakpoint-xl-new - 1) {
                :global {
                    .image {
                        display: none;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                height: 560px;
                padding: 7.5rem 5.3125rem 5rem;

                h2 {
                    width: 60%;
                }

                :global {
                    .heading-4 {
                        width: auto;
                    }

                    .moduleImage {
                        z-index: -1;
                        right: 80px;
                        width: min(48%, 360px);
                    }

                    ul {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .icon {
        position: relative;
        width: 11px;
        height: 11px;
        box-sizing: border-box;
        cursor: pointer;
        transform: rotate(180deg);
        transition: transform 0.3s;

        svg {
            display: none;
        }

        &::before {
            position: absolute;
            bottom: 11px / 2 - 1px/2;
            display: block;
            width: 11px;
            height: 0;
            border-bottom: solid 1px $white;
            content: '';
            transform: rotate(90deg);
            transition: width 0.3s;
        }

        &::after {
            position: absolute;
            bottom: 11px / 2 - 1px/2;
            display: block;
            width: 11px;
            height: 0;
            border-bottom: solid 1px $white;
            content: '';
        }

        &.close {
            position: absolute;
            top: 30px;
            right: 30px;
            width: 35px;
            transform: rotate(135deg);

            &::before {
                bottom: 35px / 2 - 3px/2;
                width: 35px;
                border-bottom: solid 3px $white;
                transform: rotate(90deg);
            }

            &::after {
                bottom: 35px / 2 - 3px/2;
                width: 35px;
                border-bottom: solid 3px $white;
            }
        }
    }
}

.click-updated {
    .selectors {
        @include click-horizontal-scroll();

        .arrow {
            display: none;
        }

        > div {
            width: auto;

            .selector {
                width: 167px;
                height: 55px;

                p {
                    overflow: hidden;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &.default,
                &.active {
                    width: auto;

                    p {
                        max-width: 300px;
                        padding-right: 1.25rem;
                        font-weight: 700;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            padding: 0 2.5rem;

            > div {
                .selector {
                    width: 200px;
                    height: 100px;

                    &.default,
                    &.active {
                        p {
                            max-width: none;
                            padding-right: 6.25rem;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            padding: 0;

            > div {
                width: min(312.5px, calc((100vw - 160px) / 4));

                .selector {
                    width: auto;
                    height: 153px;
                    padding: 1rem;

                    &.default,
                    &.active {
                        p {
                            padding-right: 0;
                        }
                    }

                    .arrow {
                        position: relative;
                        display: block;
                    }
                }

                .selector_expanded {
                    width: auto;
                    height: 453px;
                    padding: 1rem;

                    &.default,
                    &.active {
                        p {
                            padding-right: 0;
                        }
                    }

                    .arrow {
                        position: relative;
                        display: block;
                    }
                }
            }
        }
    }
}

.click,
.click-updated {
    .selectors {
        &__btns {
            display: none;
            cursor: pointer;
        }

        .selector {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }

        &__expanded {
            display: flex;
            width: 100%;
            max-width: 1440px !important;
            justify-content: space-between;
            padding: 0 40px;

            > div {
                width: 100%;

                .selector {
                    height: 60px;
                    cursor: pointer;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            &__expanded {
                > div {
                    .selector {
                        height: 100px;
                        cursor: pointer;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            max-width: 1280px;
            scroll-behavior: smooth;

            &__expanded {
                > div {
                    .selector {
                        height: 100px;
                        cursor: pointer;
                    }
                }
            }

            &__wrapper {
                display: flex;
                align-items: center;

                &.hidden {
                    .selectors__btns {
                        display: none;
                    }
                }

                .selectors__btns {
                    display: block;

                    &.disable {
                        cursor: not-allowed;

                        svg {
                            fill: $gray-3;
                        }
                    }

                    &.prev {
                        transform: rotate(90deg);
                    }

                    &.next {
                        transform: rotate(-90deg);
                    }

                    > svg {
                        width: 29px;
                        height: 16px;
                        margin: 0 1.875rem;
                        fill: $black;

                        .inactive {
                            fill: $gray-2;
                        }
                    }
                }
            }
        }
    }

    &.dark {
        .selectors {
            .selector {
                background-color: $white;
                color: $black;

                &.default,
                &.active {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        .selected {
            background-color: $black;
            color: $white;
        }
    }

    &.darkGrayBackgrouund {
        .selectors {
            .selector {
                background-color: $gray-4;
                color: $white;

                &.default,
                &.active {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        .selected {
            background-color: $black;
            color: $white;
        }
    }
}

.hover {
    .selectors {
        .selector {
            padding: 1.375rem 1rem;
            border-bottom: 1px solid $gray-3;

            &.active {
                border-color: $black;
                border-bottom-width: 3px;

                p {
                    color: $black;
                    font-weight: 600;
                }
            }

            p {
                color: $gray-5;
                font-size: 1.25rem;
            }
        }

        .arrow {
            display: none;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        display: flex;
        max-width: 1000px;
        margin: auto;

        .selectors,
        .selected {
            flex: 0 0 50%;
        }

        .selectors {
            padding: 0 2.5rem;

            .selector {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .active {
                .arrow {
                    display: block;
                }
            }
        }

        .selected {
            :global {
                .moduleImage {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        .selectors {
            padding: 0 5rem 0 0;
        }
    }
}
