@import '../../lib/styles/variables';

.person {
    width: 100%;
    max-width: 320px;
    padding: 0 1.25rem;
    margin: auto;

    .name, p {
        text-align: center;
    }

    .name {
        margin: 2.375rem 0 .625rem;

        + p {
            margin-top: 0;
        }
    }

    p {
        font-family: $calibre;
        font-size: 1.125rem;

        &.role {
            margin-bottom: 1.25rem;
            color: $blue;
        }
    }

    &.quote {
        display: flex;
        max-width: none;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: auto;

        @media only screen and (min-width: $breakpoint-lg-new) {
            justify-content: flex-start;
        }

        .bio {
            margin-left: 10px;
        }

        .name, p {
            margin: 0;
            text-align: left;
        }

        .name {
            color: $secondary;
            font-size: 1.25rem;
        }

        .role {
            display: none;
        }

        :global {
            .text {
                color: $black;
                font-size: 1.125rem;
            }
        }
    }
}
