@import '../../lib/styles/variables';

.Carousel,
.carousel {
    &__wrapper {
        margin: auto;

        :global {
            .carousel {
                &.carousel-slider {
                    margin: auto;
                }

                .slide {
                    background-color: transparent;
                }

                .control-dots {
                    margin: 0;

                    .dot {
                        width: 18px;
                        height: 6px;
                        border-radius: 0;
                        margin: 0 0.1875rem;
                        background: $secondary-dark;
                        box-shadow: none;
                        cursor: pointer;
                        opacity: 1;

                        &.selected,
                        &.hover {
                            background: $white;
                        }
                    }
                }
            }
        }
    }

    .body {
        margin-bottom: 1.25rem;
        text-align: center;

        .image {
            margin: auto;
        }
    }
}

.Carousel {
    .body {
        margin-bottom: 1.25rem;
        text-align: center;

        .image {
            margin: auto;
        }
    }
}
