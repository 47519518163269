@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.overlay-hero {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;

    h1, h2, p {
        color: $secondary-dark;
        text-align: center;

        @media only screen and (min-width: $breakpoint-desktop) {
            text-align: left;
        }
    }

    h1, h2 {
        margin: 0;
        font-family: $tiempos-light;
        font-size: 50px;
        line-height: calc(60 / 50);
    }

    h1 {
        margin-top: 60px;
    }

    p {
        max-width: 320px;
        margin: 40px auto 80px;
        font-size: 18px;
        line-height: calc(24 / 18);

        @media only screen and (min-width: $breakpoint-desktop) {
            margin-left: 0;
        }
    }

    > picture {
        position: relative;
        top: -150px;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > picture {
            top: -250px;
            height: 280px;
        }
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        > picture {
            top: -400px;
        }
    }

    .overlay-hero__top {
        padding-bottom: 60px;
        margin: 0;
        background-color: $gray;

        @media only screen and (min-width: $breakpoint-desktop) {
            padding: 60px 20px;
        }

        > div {
            display: flex;
            flex-direction: column;
            padding: 0;

            @media only screen and (min-width: $breakpoint-desktop) {
                max-width: 1110px;
                flex-direction: row;
            }
        }
    }
}
