@import './Basic.module.scss';

.hero-product {
    height: 712px;

    > .body {
        @extend %absolute-x;

        z-index: 1;
        top: 90px;

        padding: 0 20px;

        h1 {
            margin-bottom: 25px;
            font-size: 30px;
            letter-spacing: -0.69px;

            + p {
                &:not(.heading) {
                    font-size: 14px;
                    line-height: 1;
                }
            }
        }

        > p, h1 {
            text-align: center;
        }

        > p {
            &:last-of-type {
                @include viomeButton('black');
            }
        }

        .inline {
            margin: 80px 0 60px;
        }
    }

    > picture {
        @extend %absolute-full;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        height: 988px;

        > .body {
            top: 130px;
            width: 510px;
            max-width: none;
            padding: 0;

            h1 {
                margin-bottom: 30px;
                font-size: 50px;
                letter-spacing: -1.15px;

                + p {
                    &:not(.heading) {
                        font-size: 20px;
                    }
                }
            }

            .inline {
                margin: 95px 0 70px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        height: auto;

        > .body {
            top: 140px;
            width: 870px;

            h1 {
                margin-bottom: 30px;
                font-size: 70px;
                letter-spacing: -1.62px;

                + p {
                    &:not(.heading) {
                        font-size: 24px;
                    }
                }
            }
        }

        > picture {
            position: static;
        }
    }
}
