@import '../../lib/styles/variables';

.hero {
    margin-top: 4.0625rem;

    .heading-1 {
        max-width: 635px;
        margin: 0 auto 0.5rem;
        font-family: $tiempos;
        font-size: 3.125rem;
        font-weight: normal;
        line-height: 1.2;
        text-align: center;

        u {
            text-decoration: none;
        }
    }

    .heading-2 {
        margin-bottom: 1.875rem;
        color: $secondary;
        font-size: 1.5rem;
        line-height: 1.08;
    }

    .heroTop {
        position: relative;

        .body {
            padding: 0 1.25rem;
            margin-bottom: 3.75rem;
        }

        p {
            text-align: center;

            &:not(.heading) {
                font-family: $tiempos-light;
                font-size: 1.25rem;
                font-style: italic;
                letter-spacing: 0.5px;
                text-decoration: underline;
            }
        }

        picture {
            &:first-of-type {
                width: 100%;
            }

            &:last-of-type {
                position: absolute;
                right: 50%;
                bottom: 0;
                width: 366px;
                transform: translate(50%, 50%);
            }

            img {
                width: 100%;
            }
        }
    }

    .heroBottom {
        padding-top: 11.25rem;
        background: $gray;

        .wrapper {
            > picture {
                margin-bottom: 1.875rem;
            }
        }

        .body {
            width: 100%;
            max-width: 370px;
            padding: 0 1.25rem 8.4375rem;
            margin: auto;

            > p {
                &:not(.heading) {
                    padding-left: 1.9375rem;
                    font-family: $calibre-medium;
                    font-size: 1.125rem;
                    font-weight: 500;
                }
            }

            ul {
                margin: 0.8125rem 0 1.875rem;
            }

            li {
                display: flex;
                align-items: flex-start;
                padding-bottom: 0.75rem;
                margin-bottom: 0.75rem;

                &::before {
                    display: block;
                    margin: 0.1875rem 1rem 0 0.125rem;
                    content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
                }

                &:first-of-type {
                    &::before {
                        display: none;
                    }
                }

                &:last-of-type {
                    padding: 0;
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                    line-height: calc(20 / 16);
                }

                .inline {
                    width: 1.4375rem !important;
                    margin-right: 0.5625rem;

                    .image {
                        margin-top: -0.1875rem;
                    }
                }
            }

            .inline {
                :global {
                    .price {
                        color: $secondary;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .heroTop {
            picture {
                &:first-of-type {
                    width: 680px;
                    margin: auto;
                }

                &:last-of-type {
                    width: 652px;
                }
            }
        }

        .heroBottom {
            padding: 16.5625rem 0 11.0625rem;

            .wrapper {
                > picture {
                    margin-bottom: 3.125rem;
                }
            }

            .body {
                padding: 0;

                .inline {
                    justify-content: flex-start;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        margin-top: 0;

        .heroTop {
            .wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .body {
                padding: 0;
                margin-right: calc(55 / 1440 * 100%);
                margin-left: calc(165 / 1440 * 100%);
            }

            h1, p {
                margin: 0;
                text-align: left;
            }

            picture {
                &:first-of-type {
                    flex: 0 0 680px;
                    margin: 0;
                }
            }
        }

        .heroBottom {
            padding: 10.9375rem 0;

            .wrapper {
                justify-content: space-between;
                padding: 0 10.3125rem;

                > picture {
                    margin-bottom: 0;
                }
            }

            .body {
                margin: 0;
            }
        }
    }
}
