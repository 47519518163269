@import 'lib/styles/variables';

.Guide {
    display: block;
    width: 100%;

    .featuredImage {
        width: 100%;
    }

    .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.9375rem;
        text-align: left;
    }

    .guideType {
        padding-bottom: 1.125rem;
        font-size: 1.125rem;
        line-height: 1.3;
    }

    .title {
        padding-bottom: 1.125rem;
        font-size: 1.875rem;
        line-height: 1.3;
    }

    .description {
        padding-bottom: 2.5rem;
        font-size: 1.125rem;
        line-height: 1.3;
    }

    .link {
        font-size: 1rem;
        line-height: 1.5;
    }

    .fill {
        height: stretch;
    }

    &.dark {
        background-color: $black;

        .guideType {
            color: $gray4;
        }

        .detail {
            svg {
                fill: $white;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .detail {
            padding: 1.875rem;
            text-align: left;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        display: flex;
        flex-direction: row;

        .detail {
            max-width: 640px;
            height: 426px;
            padding: 2.5rem;
        }

        .featuredImage {
            width: 50%;
        }
    }
}
