@import 'lib/styles/variables';

.grid-sbs {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;

    .selectors {
        border-top: 1px solid $gray-3;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        flex-direction: row;
        align-items: center;
        gap: 3.125rem;

        .selectors__wrapper {
            width: 324px;
            flex-shrink: 0;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        gap: 14.375rem;

        .selectors__wrapper {
            width: 477px;
        }
    }
}

.grid-dropdown {
    &.light {
        .selector {
            &.active {
                border: solid 1px $gray-6;
            }
        }
    }

    &.dark {
        .selector {
            border: solid 1px $gray-6;

            &.active {
                border-color: $white;
            }
        }
    }

    .selectors {
        display: grid;

        .selector {
            &.active {
                :global {
                    .row__container {
                        > .moduleText {
                            .image {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        :global {
            .row__container {
                > .moduleText {
                    .image {
                        width: 17px;
                        flex: 0 0 17px;
                        margin-right: 0;
                        transition: 0.2s ease-in-out;
                    }
                }
            }
        }
    }

    .selected {
        overflow: hidden;
        max-height: 0;
        transition: 0.2s ease-in-out;

        &.active {
            max-height: 2000px;
            transition: 0.2s ease-in-out;
        }
    }
}
