@import 'lib/styles/variables';

.Carousel {
    position: relative;
    width: 100%;

    :global {
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            text-align: center;
        }
    }

    .indicators {
        display: flex;
        justify-content: center;
        margin: 2.5rem 0 0;

        .indicator {
            display: block;
            width: 10px;
            height: 10px;
            margin: 0 0.1875rem;
            opacity: .2;
        }

        :global {
            .swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    .navigation {
        .arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            display: flex;
            width: 19px;
            height: 30px;
            cursor: pointer;
            opacity: .2;

            &:hover {
                opacity: 1;
            }

            &--prev {
                left: 10px;
            }

            &--next {
                right: 10px;
                left: auto;
                transform: scaleX(-1);
            }
        }
    }

    &.full {
        max-width: none;

        .navigation {
            display: none;

            .arrow {
                &--prev {
                    left: -40px;
                }

                &--next {
                    right: auto;
                    left: calc(100% + 10px);
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                display: block;
            }
        }
    }

    &.large {
        .before {
            p {
                font-size: 1.875rem;
                letter-spacing: -0.0431rem;
                line-height: 1.27;
                white-space: nowrap;
            }
        }
    }

    &__item {
        border-bottom: 2px solid $primary;

        p {
            font-family: $calibre-medium;
            font-size: 1.0625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.29;
        }

        .before {
            display: flex;
            min-height: 60px;
            align-items: center;
            padding: 0.625rem 0 0.9375rem;
            cursor: pointer;

            .arrow {
                margin-right: 0.625rem;
                transition: .3s ease-in-out;
            }

            p {
                width: calc(100% - 60px);
                padding-top: 0.3125rem;
            }
        }

        .after {
            overflow: hidden;
            max-height: 0;
            transition: all .3s ease-in-out;

            p {
                padding: 0.3125rem 0 2.5rem;
            }
        }

        .active {
            .arrow {
                transform: rotate(-180deg);
            }

            + .after {
                max-height: 2000px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;
        margin: auto;

        &.large {
            .before {
                p {
                    font-size: 2.25rem;
                    letter-spacing: -0.0519rem;
                    line-height: 1.28;
                }
            }
        }

        &__item {
            .before {
                min-height: 80px;
            }

            p {
                font-size: 1.25rem;
                letter-spacing: -0.0375rem;
                line-height: 1.3;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 610px;
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        max-width: 730px;

        :global {
            .row--bg {
                .row__container {
                    padding: 0 2.9375rem;
                }
            }
        }
    }
}
