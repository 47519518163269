@import '../../lib/styles/variables';

.Expanding {
    &__wrapper {
        overflow: hidden;
        transition: all .2s ease-in-out;

        @for $i from 0 through 5 {
            &.viewable--#{$i} {
                max-height: $i * 1.5625rem;
            }
        }

        &.active {
            max-height: 2000px;
        }

        p {
            + p {
                margin-top: 1.25rem;
            }
        }
    }

    button {
        margin-top: 0.625rem;
        font-family: $calibre-medium;
        font-size: 1.125rem;
        text-decoration: underline;
    }
}
