@import '../../lib/styles/variables';

section .GlossaryTerm {
    &__item {
        padding: 0 1.125rem;
        margin: 0 auto;
        margin-bottom: 3rem;

        @media only screen and (min-width: $breakpoint-tablet) {
            padding-right: 0;
            padding-left: 0;
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            padding-right: 2rem;
            padding-bottom: 5rem;
        }

        h1 {
            font-family: $calibre-semibold;
        }

        h2 {
            padding-bottom: 1.25rem;
            padding-left: 0;
            font-family: $calibre-semibold;
            font-size: 3.25rem;
        }

        h3 {
            padding-bottom: 1.25rem;
            color: $primary;
            font-family: $calibre-semibold;
            font-size: 1.875rem;
        }

        h4 {
            padding-bottom: 1.25rem;
            color: $primary;
            font-family: $calibre-semibold;
            font-size: 1.375rem;
        }

        h5 {
            padding-bottom: 1.25rem;
            color: $primary;
            font-size: 1.25rem;
        }

        p {
            padding: 0 0 1.25rem;
            margin-top: 0;
            font-size: 1.25rem;
        }

        ul, ol {
            padding-right: 1rem;
            padding-bottom: 1.25rem;
            padding-left: 1rem;
            list-style: initial;
        }

        ul > li > p, ol > li > p {
            padding-bottom: 0;
        }

        a.title {
            text-decoration: none;
        }

        a.readmore {
            color: $secondary;
            font-family: $calibre-medium;
            font-size: 1.25rem;
            text-decoration: underline;
        }

        .referenceUrl {
            a {
                color: $gray-darkest;
                overflow-x: hidden;
            }
        }
    }
}
