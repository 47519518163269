@import '../../lib/styles/variables';

.HorizontalScroll {
    margin: 6.25rem auto 0;

    p {
        &:not(.heading) {
            color: $charcoal;
            font-size: 0.75rem;
        }

        &.heading {
            display: flex;
            overflow: hidden;
            -webkit-box-orient: vertical;
            font-family: $calibre-semibold;
            font-size: 1rem;
            -webkit-line-clamp: 2;
            line-height: 1;
            text-overflow: ellipsis;
        }
    }

    &__container {
        padding-right: 0;

        h4 {
            max-width: 250px;
            margin-bottom: 1.875rem;
        }
    }

    &__items {
        display: grid;
        justify-content: start;
        margin: 0 auto;
        gap: 2rem;
        grid-auto-flow: column;
        -ms-overflow-style: none; /* IE and Edge */
        overflow-y: hidden;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            display: none;
        }

        &::after {
            display: block;
            width: 10px;
            height: 100%;
            content: '';
        }

        > .section {
            width: 280px;
            background-color: $gray-dark;
            scroll-snap-align: center;
        }
    }

    .section {
        h4 {
            display: none;
        }

        .wrapper {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .body {
        display: flex;
        height: 100px;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.25rem;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &__container {
            h4 {
                max-width: none;
                text-align: center;
            }
        }

        &__items {
            &::after {
                width: 20px;
            }
        }

        .body {
            height: 120px;
        }

        p {
            &.heading {
                -webkit-line-clamp: 3;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &__container {
            max-width: 1200px;
        }

        &__items {
            &.center {
                justify-content: center;
            }

            &::after {
                display: none;
            }
        }
    }
}
