@import '/lib/styles/variables';

.viome__footer {
    padding: 5.625rem 1.25rem 2.5rem;
    background-color: $primary;

    .footerTop {
        text-align: center;
    }

    p,
    .footerLink {
        color: $white;
        font-size: 1rem;
    }

    .topLinkHead {
        margin-bottom: 1.25rem;
        font-family: $calibre-semibold;
        font-size: 1rem;
        line-height: 1;
    }

    hr {
        border-color: $gray-dark;
        margin: 2.5rem 0 1.875rem;
    }
}

.logo {
    display: block;
    margin-bottom: 0.3125rem;
    text-align: center;
}

.topLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1.4063rem 6.25rem;
}

.topLink {
    display: flex;
    width: 145px;
    flex-direction: column;
    margin: 3.75rem 1.4063rem 0;
    text-align: center;

    .footerLink {
        margin-bottom: 0.25rem;
    }
}

.appSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .footerLink {
        margin: 0 0.5625rem;
    }

    p {
        flex-basis: 100%;
        margin-bottom: 1.5625rem;
    }
}

.socialSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3.75rem -0.625rem 0;

    .footerLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0 0.625rem;

        img {
            width: 14px;
            margin-bottom: 0.25rem;
        }

        &--fb {
            img {
                width: 8px;
            }
        }

        &--tw {
            img {
                padding-top: 0.125rem;
            }
        }

        &--ig {
            img {
                padding-top: 0.0625rem;
            }
        }

        &--yt {
            img {
                padding-top: 0.25rem;
            }
        }

        &--v {
            img {
                width: 16px;
                padding-top: 1px;
            }
        }
    }
}

.footerText {
    margin-bottom: 1.25rem;

    p {
        + p {
            margin-top: 0.75rem;
        }
    }
}

.fdaCompliance {
    padding: 0.5rem 0.3125rem 0.3125rem;
    border: 1px solid $white;
    margin-bottom: 1.25rem;

    p {
        padding: 0.3125rem;

        + p {
            padding-top: 1rem;
        }
    }
}

.footerBottom {
    display: flex;
    flex-direction: column;

    .footerCopyright {
        margin: 0;
    }

    .bottomLinks {
        margin-top: 1.25rem;

        .footerLink {
            &::after {
                display: inline;
                content: ' | ';
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }
        }
    }

    iframe {
        align-self: center;
        margin-top: 2.5rem;
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    .viome__footer {
        padding: 6.875rem 2.5rem 5rem;
    }

    .topLinks {
        justify-content: space-around;
        margin: 0 0 6.25rem;
    }

    .topLink {
        width: 150px;
        margin: 3.125rem 0 0;
    }

    .appSection {
        align-items: center;

        .footerLink {
            &:last-of-type {
                margin-right: 0;
            }
        }

        p {
            flex-basis: 91px;
            margin: 0 0.8125rem 0 0;
            text-align: left;
        }
    }

    .socialSection {
        margin-top: 3.125rem;

        .footerLink {
            flex-direction: row;

            img {
                margin: 0 0.5rem 0 0;
            }

            &--fb {
                img {
                    padding-bottom: 0.3125rem;
                }
            }

            &--tw {
                img {
                    padding: 0 0 0.375rem;
                }
            }

            &--ig {
                img {
                    padding: 0 0 0.3125rem;
                }
            }

            &--yt {
                img {
                    padding: 0 0 0.5rem;
                }
            }

            &--v {
                img {
                    padding: 0 0 4px;
                }
            }
        }
    }

    .footerBottom {
        position: relative;
        display: flex;
        flex-direction: row;

        .bottomLinks {
            margin: 0 0 0 0.3125rem;
        }

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .viome__footer {
        padding: 0;

        hr {
            margin: 1.875rem 0 1.5625rem;
        }
    }

    .footerWrapper {
        max-width: 1110px;
        padding: 10.625rem 0 7.5rem;
        margin: auto;
    }

    .footerTop,
    .footerMiddle {
        display: flex;
        justify-content: space-between;
    }

    .footerMiddle {
        flex-direction: row;
        align-items: flex-start;
        margin: 8.125rem 0 0;
    }

    .topLinks {
        margin: 0 -0.9375rem;
    }

    .topLink {
        width: 100px;
        margin: 0 0.9375rem;
        text-align: center;
    }

    .appSection {
        justify-content: flex-end;
    }

    .socialSection {
        margin: 0;

        .footerLink {
            &--fb {
                margin-left: 0;
            }
        }
    }
}
