@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

@keyframes fadeNewMessage {
    10% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.slideshow {
    > .body {
        padding-bottom: 3.75rem;

        p {
            &.heading-2 {
                width: 270px;
                margin: auto;
                text-align: center;
            }
        }
    }

    .slides {
        .slide {
            p {
                text-align: center;
            }

            .image {
                margin: auto;
            }

            + .slide {
                margin-top: 2.1875rem
            }

            .before {
                display: flex;
                justify-content: center;
                transition: none;

                p {
                    display: inline-flex;
                    height: 46px;
                    align-items: center;
                    padding: 0 2.1875rem;
                    border-radius: 1.875rem;
                }
            }

            .after {
                overflow: hidden;
                max-height: 0;
                transition: all .2s ease-in-out;

                .inline {
                    margin-top: 3.125rem;
                }
            }

            .active {
                .after {
                    max-height: 2000px;
                }

                .before {
                    p {
                        background-color: $black;
                        color: $white;
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            padding-bottom: 2.5rem;

            p {
                &.heading-2 {
                    width: 460px;
                    font-size: 2.125rem;
                    line-height: 1.2;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            padding-bottom: 5rem;

            p {
                &.heading-2 {
                    width: 580px;
                    font-size: 2.5rem;
                }
            }
        }

        .slides {
            position: relative;
            display: flex;
            width: 840px;
            height: 430px;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 4rem;
            margin: auto;

            .slide {
                + .slide {
                    margin-top: 1rem;
                }

                .before {
                    &:hover &:not(:focus) {
                        p {
                            background-color: $black;
                            color: $white;
                            font-size: 1.5rem;
                        }
                    }
                }

                .after {
                    position: absolute;
                    top: 0;
                    right: -20px;
                    max-height: none;
                    opacity: 0;
                    transition: none;

                    .inline {
                        margin-top: 0;
                    }
                }

                .active {
                    .after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.slideshow--hero {
    height: 828px;

    .slides {
        position: relative;
    }

    .slide {
        @extend %absolute-x;

        top: 0;
        height: 828px;

        .body {
            @extend %absolute-x;

            bottom: 0;
            opacity: 0;
        }
    }

    .slide__wrapper {
        opacity: 0;
        transition: all .5s ease-in-out;

        &.active {
            opacity: 1;

            .body {
                animation: fadeNewMessage 5s;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        height: 1132px;

        .slide {
            height: 1132px;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        height: 840px;

        .slide {
            height: 840px;

            .body {
                right: calc(236 / 1440 * 100%);
                bottom: 50%;
                transform: translateY(50%);
            }
        }
    }
}
