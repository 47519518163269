@import '../../../../lib/styles/variables';

.nav {
    border-bottom: 1px solid $black;

    .links {
        display: flex;
        align-items: center;
        margin: 3.75rem 0;
        text-align: center;

        li {
            display: block;
            padding: 0 0.625rem;
            border-right: 1px solid $gray-darker;
            list-style-type: none;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                border: 0;
            }

            a {
                margin-left: 0;
                text-decoration: none;
            }
        }
    }
}
