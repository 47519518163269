@import '../../../lib/styles/variables';

.Preview {
    position: fixed;
    z-index: 100;
    bottom: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    padding: 1rem 1.3125rem 0.6875rem;
    background-color: $gold;
    font-size: 1.125rem;
    line-height: 1.17;

    a {
        text-decoration: underline;
    }
}
