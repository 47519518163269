@import '../../../lib/styles/variables';

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0);
    }
}

.marquee {
    > .body {
        width: 250px;
        margin: 0 auto 100px;

        p {
            font-family: $calibre-semibold;
            font-size: 25px;
            line-height: 1.15;
            text-align: center;

            u {
                -webkit-background-clip: text;
                background-image: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                -webkit-box-decoration-break: clone;
                text-decoration: none;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__wrapper {
        overflow: visible;
        max-width: 100%;
    }

    &__container {
        display: inline-flex;
        overflow: visible;
        animation: marquee 10s linear infinite;
        white-space: nowrap;

        &:hover {
            animation-play-state: paused;
        }
    }

    &--instagram {
        .marquee-item {
            &-1 {
                margin-top: 75px;
            }

            &-2, &-4 {
                margin-top: 38px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            width: 500px;

            p {
                font-size: 40px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            margin-bottom: 125px;
        }

        &--instagram {
            .marquee-item {
                &-1 {
                    margin-top: 100px;
                }

                &-2, &-4 {
                    margin-top: 50px;
                }
            }
        }
    }
}
