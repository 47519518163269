@import '/lib/styles/variables';

.Accordion {
    width: 100%;

    &.large {
        .before {
            p {
                font-size: 1.875rem;
                letter-spacing: -0.0431rem;
                line-height: 1.27;
                white-space: nowrap;
            }
        }
    }

    &.dark {
        .Accordion__item {
            border-color: $white;

            svg {
                path {
                    stroke: $white;
                }
            }
        }
    }

    &.legacy {
        .Accordion__item {
            .before {
                :global {
                    .text {
                        display: flex;

                        img {
                            padding: 0 1.125rem;

                            + * {
                                padding-top: 0.625rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__item--rainbow {
        border-image: linear-gradient(to right, #dc1e4e 0%, #7348b7 50%, #00a27f 100%);
        border-image-slice: 1;
    }

    &__item {
        border-bottom: 2px solid $primary;

        p {
            font-family: $calibre-medium;
            font-size: 1.0625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.29;
        }

        .before {
            display: flex;
            min-height: 60px;
            align-items: center;
            padding: 0.625rem 0 0.9375rem;
            cursor: pointer;

            .arrow {
                margin-right: 0.625rem;
                transition: .3s ease-in-out;
            }

            p {
                width: calc(100% - 60px);
                padding-top: 0.3125rem;
            }
        }

        .after {
            overflow: hidden;
            max-height: 0;
            transition: all .3s ease-in-out;

            p {
                padding: 0.3125rem 0 2.5rem;
            }
        }

        .active {
            .arrow {
                transform: rotate(-180deg);
            }

            + .after {
                max-height: 2000px;
            }
        }

        :global {
            .text {
                br {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }

    &__body {
        &--after {
            :global {
                .CTA {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;
        margin: auto;

        &.large {
            .before {
                p {
                    font-size: 2.25rem;
                    letter-spacing: -0.0519rem;
                    line-height: 1.28;
                }
            }
        }

        &.full {
            max-width: none;
        }

        &__item {
            .before {
                min-height: 80px;
            }

            p {
                font-size: 1.25rem;
                letter-spacing: -0.0375rem;
                line-height: 1.3;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 610px;

        &.full {
            max-width: none;
        }
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        max-width: 730px;

        &.large {
            .before {
                p {
                    font-size: 2.5rem;
                    letter-spacing: -0.0575rem;
                    line-height: 1.15;
                }
            }
        }

        &.full {
            max-width: none;
        }
    }
}
