@import '../../../../lib/styles/variables';

.Gallery {
    width: 100%;

    :global {
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            text-align: center;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
        }

        .swiper-thumbs {
            .swiper-slide {
                opacity: 0.35;

                &.swiper-slide-thumb-active {
                    opacity: 1;
                }
            }
        }
    }

    .CustomNavigation {
        position: absolute;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 0;

        &__Arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 10%;
            height: 79px;
            margin-top: 0;
            background-color: $white;
            color: $black;
            font-weight: bold;
            opacity: 0.8;

            &__Prev {
                left: 0;
            }

            &__Next {
                right: 0;
            }

            &:hover {
                opacity: 0.9;
            }
        }
    }

    &__item {
        border-bottom: 2px solid $primary;

        p {
            font-family: $calibre-medium;
            font-size: 1.0625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.29;
        }

        .before {
            display: flex;
            min-height: 60px;
            align-items: center;
            padding: 0.625rem 0 0.9375rem;
            cursor: pointer;

            .arrow {
                margin-right: 0.625rem;
                transition: 0.3s ease-in-out;
            }

            p {
                width: calc(100% - 60px);
                padding-top: 0.3125rem;
            }
        }

        .after {
            overflow: hidden;
            max-height: 0;
            transition: all 0.3s ease-in-out;

            p {
                padding: 0.3125rem 0 2.5rem;
            }
        }

        .active {
            .arrow {
                transform: rotate(-180deg);
            }

            + .after {
                max-height: 2000px;
            }
        }
    }

    &__slideContainer {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;

        div img {
            width: auto !important;
            min-width: auto !important;
            max-width: none !important;
            height: 100% !important;
            min-height: auto !important;
            max-height: none !important;
        }

        img {
            height: 100%;
        }
    }

    .imageThumbnail {
        width: 100%;
        cursor: pointer;
    }

    .videoThumbnail {
        position: relative;
        height: 79px;
        cursor: pointer;

        div {
            height: 79px;
        }

        .playIcon {
            position: absolute;
            display: flex;
            width: 100%;
            height: 79px;
            align-items: center;
            justify-content: center;
        }

        img {
            width: auto !important;
            min-width: auto !important;
            max-width: none !important;
            height: 79px !important;
            min-height: auto !important;
            max-height: none !important;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;
        margin: auto;

        &.large {
            .before {
                p {
                    font-size: 2.25rem;
                    letter-spacing: -0.0519rem;
                    line-height: 1.28;
                }
            }
        }

        &__item {
            .before {
                min-height: 80px;
            }

            p {
                font-size: 1.25rem;
                letter-spacing: -0.0375rem;
                line-height: 1.3;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 610px;
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        max-width: 730px;

        :global {
            .row--bg {
                .row__container {
                    padding: 0 2.9375rem;
                }
            }
        }
    }
}
