@import 'lib/styles/variables';
@import 'lib/styles/common';

.text {
    max-width: 100%;

    .title {
        padding-bottom: 1.875rem;
        border-bottom: 0.1875rem solid currentcolor;
        margin-bottom: 1.875rem;
    }

    .tiempos {
        font-family: $font-tiempos;

        * {
            font-family: $font-tiempos;
        }
    }

    .gradient {
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;
        font-weight: 200;
        -webkit-text-fill-color: transparent;

        &:global(.inline-block) {
            width: auto;
        }

        &--Blue {
            @extend %blue-gradient;
        }

        &--Green {
            @extend %green-gradient;
        }

        &--Purple {
            @extend %purple-gradient;
        }

        &--Rainbow {
            background-image: linear-gradient(
                90deg,
                hsl(355deg 58% 50%) 0%,
                hsl(292deg 41% 49%) 25%,
                hsl(234deg 65% 46%) 50%,
                hsl(169deg 100% 31%) 75%
            );
        }

        &--Orange {
            @extend %orange-gradient;
        }

        &--Yellow {
            @extend %yellow-gradient;
        }
    }

    .full {
        width: 100%;
    }

    .Top {
        align-items: flex-start;
    }

    .Middle {
        align-items: center;
    }

    .Bottom {
        align-items: flex-end;
    }

    .bubble {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.25rem !important;
        line-height: 40px !important;
        text-align: center;
    }

    &.noUnderline {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.resetLH {
        p,
        .headings {
            line-height: inherit;
        }
    }

    &.autoImage {
        :global {
            .image {
                margin-top: auto;
            }
        }
    }

    &.flexP {
        > p {
            display: flex;
            align-items: center;

            + p {
                margin-top: 2.5rem;
            }
        }
    }

    &.bigTitle {
        > .title {
            padding-bottom: 0.75rem;
            font-size: 2.25rem;
            line-height: 1.3;

            @media only screen and (min-width: $breakpoint-tablet) {
                font-size: 3rem;
            }
        }
    }

    img {
        display: inline-block;
    }

    span {
        width: 100%;
    }

    br {
        + .headings {
            margin-top: 0.5rem;
        }
    }

    sup {
        top: -8px;
        font-size: 60% !important;

        a {
            cursor: pointer;
            font-size: inherit !important;
            text-decoration: none;
        }
    }

    > hr {
        margin: 6px 0;
        background-color: $gray-4;
    }

    .headings {
        + .headings {
            margin-top: 0.5rem;
        }

        > span {
            &.XLPlus {
                font-size: calc(128 / 48 * 100%);
            }

            &.XL {
                font-size: 200%;
            }

            &.Biggest {
                font-size: 150%;
            }

            &.Bigger {
                font-size: 125%;
            }

            &.Smaller {
                font-size: 87.5%;
            }

            &.Smallest {
                font-size: 75%;
            }
        }
    }

    > p,
    > ol,
    > ul,
    > .headings {
        + ol,
        + ul {
            margin-top: 0.5rem;
        }
    }

    p {
        &.XLPlus {
            font-size: calc(128 / 48 * 100%);
        }

        &.XL {
            font-size: 200%;
        }

        &.Biggest {
            font-size: 150%;
        }

        &.Bigger {
            font-size: 125%;
        }

        &.Smaller {
            font-size: 87.5%;
        }

        &.Smallest {
            font-size: 75%;
        }
    }

    ol,
    ul {
        &.flex {
            display: flex;
            gap: 2rem;
        }

        &.noBullets {
            li {
                list-style: none;
            }
        }

        &.noMargins {
            margin-left: 0;
        }

        &.horizontal,
        &.noWrap {
            display: flex;
            gap: 1rem;

            li {
                display: flex;
                align-items: center;
            }
        }

        &.horizontal {
            li {
                gap: 0.25rem;
            }

            &.alignTop {
                align-items: flex-start;

                li {
                    align-items: flex-start;
                }
            }

            &.verticalAtTablet {
                @media only screen and (min-width: $breakpoint-tablet) {
                    flex-direction: column;
                    gap: 0.5625rem;
                }
            }

            &.verticalAtDesktop {
                @media only screen and (min-width: $breakpoint-lg-new) {
                    flex-direction: column;
                    gap: 0.5625rem;
                }
            }
        }

        &.noWrap {
            flex-wrap: wrap;

            li {
                white-space: nowrap;
            }
        }

        &.customBullets {
            li {
                display: flex;
                align-items: center;

                &.dash {
                    svg {
                        margin-top: 0.125rem;
                    }

                    b {
                        margin-right: 0.25rem;
                    }
                }

                svg {
                    flex: 0 0 auto;
                    margin: 0 1.5rem 0 0;
                    fill: currentcolor;
                }

                + li {
                    margin-top: 0.625rem;
                }
            }
        }

        &.alignTop {
            li {
                align-items: flex-start;

                svg {
                    margin-top: 0.375rem;
                }

                &.check {
                    svg {
                        margin-top: 0.1875rem;
                    }
                }
            }
        }

        &.reset {
            li {
                font-size: 1rem;
            }
        }

        &.topic {
            display: flex;
            flex-wrap: wrap;
            justify-content: inherit;
            gap: 8px;

            li {
                display: flex;
                align-items: center;
                padding: 2px 12px;
                border-radius: 40px;
                font-weight: 400;
                white-space: nowrap;
            }
        }

        &.borderBottom {
            border-bottom-color: inherit;

            > li {
                min-height: 40px;
                padding: 0 0 0.75rem;
                border-bottom: 1px solid;
                border-bottom-color: inherit;
            }

            > li:first-of-type {
                margin-top: 0.625rem;
            }
        }

        &.bubbles {
            padding-left: 0;
            counter-reset: elementcounter;
            list-style-type: none;

            .numbers {
                position: absolute;
                z-index: 2;
                left: 0;
                display: inline-flex;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 20px;
                line-height: 1.4;

                &::before {
                    content: counter(elementcounter);
                    counter-increment: elementcounter;
                }
            }

            li {
                position: relative;
                align-items: baseline;
                padding-left: 3.5rem;
                line-height: 2.5rem;

                > b {
                    font-size: 125%;
                }

                &:not(:last-of-type) {
                    padding-bottom: 2rem;
                }
            }
        }

        &.connected {
            padding-left: 0;
            counter-reset: elementcounter;
            list-style-type: none;

            @mixin horizontal-connected {
                display: grid;
                gap: 2.5rem;

                li {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    &::before {
                        width: 35px;
                        height: 35px;
                        flex-basis: 35px;
                        margin-right: 0;
                        margin-bottom: 2.5rem;
                        font-size: 1.25rem;
                    }

                    &:not(:last-of-type) {
                        padding-bottom: 0;

                        &::after {
                            left: 55%;
                            width: 110%;
                            height: 1px;
                        }
                    }

                    + li {
                        margin-top: 0;
                    }
                }
            }

            li {
                position: relative;
                display: flex;
                align-items: baseline;

                &::before {
                    z-index: 2;
                    display: inline-flex;
                    width: 18px;
                    height: 18px;
                    flex: 0 0 18px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    margin-right: 1rem;
                    background-color: $black;
                    color: $white;
                    content: counter(elementcounter);
                    counter-increment: elementcounter;
                    font-size: 0.8125rem;
                }

                &:not(:last-of-type) {
                    padding-bottom: 1.5rem;

                    &::after {
                        position: absolute;
                        z-index: 1;
                        top: 20px;
                        bottom: 0;
                        left: 9px;
                        width: 1px;
                        background-color: black;
                        content: '';
                    }
                }

                + li {
                    margin-top: -5px;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                grid-template-columns: repeat(3, 1fr);
            }

            &.fourwide {
                @media only screen and (min-width: $breakpoint-tablet) {
                    grid-template-columns: repeat(4, 1fr);
                }
            }

            &:not(.verticalAtDesktop) {
                @media only screen and (min-width: $breakpoint-tablet) {
                    @include horizontal-connected;
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    li {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        &::before {
                            width: 48px;
                            height: 48px;
                            flex-basis: 48px;
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            &.verticalAtDesktop {
                @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-lg-new - 1) {
                    @include horizontal-connected;
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    li {
                        &::before {
                            width: 32px;
                            height: 32px;
                            flex: 0 0 32px;
                            font-size: 1.25rem;
                        }

                        &:not(:last-of-type) {
                            padding-bottom: 2.5rem;

                            &::after {
                                left: 16px;
                            }
                        }
                    }
                }
            }
        }

        &.verticalAtDesktop.fourwide {
            @media only screen and (min-width: $breakpoint-tablet) {
                li {
                    &::before {
                        width: 35px;
                        height: 35px;
                        flex-basis: 35px;
                        font-size: 1.25rem;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                li {
                    font-size: 1.5rem;

                    &::before {
                        width: 32px;
                        height: 32px;
                        flex-basis: 32px;
                    }

                    &:not(:last-of-type) {
                        &::after {
                            left: 16px;
                            width: 1px;
                            height: 100%;
                        }
                    }
                }
            }
        }

        &.oneRemSeparation {
            li {
                &:not(:last-of-type) {
                    padding-bottom: 1rem;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                li {
                    &:not(:last-of-type) {
                        padding-bottom: 0;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                li {
                    &:not(:last-of-type) {
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        @media only screen and (max-width: $breakpoint-tablet - 1),
            only screen and (min-width: $breakpoint-lg-new) {
            &.splitList {
                display: grid;
                columns: 2;
                grid-gap: 0.5rem 1.5625rem;
                grid-template-columns: repeat(2, 1fr);

                li {
                    + li {
                        margin-top: 0;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            &.splitList {
                grid-gap: 0.5rem 2.5rem;
            }
        }
    }

    > br {
        &:last-child {
            display: none;
        }
    }

    ol {
        li {
            padding-left: 0.3125rem;

            &::marker {
                font-weight: 600;
            }

            + li {
                padding-top: 1rem;
            }
        }

        ol {
            padding: 0.75rem 0;
            list-style-type: lower-alpha;

            ol {
                list-style-type: lower-roman;
            }
        }
    }
}

.text,
span {
    @extend %font-overrides;
}

.popup {
    > div {
        > a {
            display: inline;
        }
    }
}

.inlineList {
    display: inline-block;

    .borderBottom {
        > li {
            padding: 1rem 0;
        }
    }
}
