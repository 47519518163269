@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.cta {
    max-width: 330px;
    padding: 68px 30px 60px;
    border-width: 1px;
    border-style: solid;
    margin: auto;
    border-image-slice: 1;
    border-image-source: conic-gradient(from 0.4turn, #009e82, #028e79 0.18turn, #ca3550 0.44turn, #6b49ae 0.77turn, #009e82);

    > .body {
        > p {
            &.heading {
                margin-bottom: 30px;
            }

            &.heading-2 {
                font-size: 30px;
                line-height: 1.2;
            }

            &.heading-3 {
                display: flex;
                width: 190px;
                height: 40px;
                align-items: center;
                justify-content: center;
                padding-top: 4px;
                margin-top: 60px;
                background-image: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                color: $white;
                font-size: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            &:not(.heading) {
                color: $font-gray;
                font-size: 17px;
                line-height: 1.29;
            }
        }

        ul {
            margin-top: 24px;

            li {
                text-align: center;

                + li {
                    margin-top: 25px;
                }

                &:first-of-type {
                    @include viomeButton('secondary');

                    a {
                        width: 100%;
                    }
                }

                a {
                    u {
                        font-family: $calibre-medium;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 676px;
        padding: 80px 90px 85px;

        > .body {
            > p {
                &.heading {
                    margin-bottom: 35px;
                }

                &.heading-2 {
                    width: 430px;
                    font-size: 40px;
                }

                &:not(.heading) {
                    width: 500px;
                    font-size: 20px;
                }
            }

            ul {
                display: flex;
                align-items: center;
                margin-top: 50px;

                li {
                    + li {
                        margin: 0 0 0 30px;
                    }

                    &:first-of-type {
                        a {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        position: relative;
        max-width: 1110px;

        > .body {
            > p {
                &.heading-2 {
                    width: 820px;
                }

                &.heading-3 {
                    margin-top: 0;
                }
            }
        }

        > picture {
            position: absolute;
            right: 72px;
            bottom: 90px;
        }
    }
}
