@import './Basic.module.scss';

.rainbow-list {
    display: flex;
    max-width: 370px;
    flex-direction: row-reverse;
    padding: 0 20px 50px 40px;
    margin: auto;

    > .body, > picture {
        flex: 0 0 auto;
    }

    > .body {
        max-width: none;
        padding: 0;
        margin: 0;

        ul {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            li {
                display: flex;

                p {
                    flex: 0 1 auto;
                    font-size: 15px;
                    line-height: 1.33;
                    text-align: left;
                }

                .inline {
                    width: 56px !important;
                    flex: 0 0 56px;

                    .image {
                        margin: auto;
                    }

                    + p {
                        margin-left: 24px;
                    }
                }
            }
        }
    }

    > picture {
        height: 716px;
        margin-right: 24px;

        img {
            width: 9px;
            height: 716px;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 678px;
        padding: 0 0 50px;

        > .body {
            width: 100%;

            ul {
                li {
                    p {
                        font-size: 18px;
                    }

                    .inline {
                        + p {
                            margin-left: 40px;
                        }
                    }
                }
            }
        }

        > picture {
            height: auto;
            margin-right: 30px;

            img {
                height: auto;
            }
        }
    }
}
