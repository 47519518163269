@import 'lib/styles/variables';

.study {
    padding: 1.875rem 2.8125rem;
    border: solid 1px $gray-dark2;
    margin-top: 1.25rem;

    p {
        &.header {
            margin-top: 1.875rem;
            color: $secondary;
        }
    }

    :global {
        .cta {
            margin-top: 1.25rem;
        }
    }
}
