@import 'lib/styles/variables';

.image {
    &__wrapper {
        width: 100%;

        &.fixed {
            > * {
                max-width: 100%;
            }
        }

        &.intrinsic {
            display: flex;
        }

        &.bg {
            position: absolute;
            z-index: 1;
            inset: 0;
        }

        &.left {
            justify-content: flex-start;
            margin: 0 auto 0 0;
            text-align: left;
        }

        &.right {
            justify-content: flex-end;
            margin: 0 0 0 auto;
            text-align: right;
        }

        &.center {
            justify-content: center;
        }

        &.center,
        &.left-at-tablet,
        &.left-at-desktop {
            margin: 0 auto;
        }

        &.zero {
            margin: 0;
        }

        video {
            max-width: 100%;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            &.left-at-tablet {
                justify-content: flex-start;
                margin: 0 auto 0 0;
                text-align: left;
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            &.left-at-desktop {
                justify-content: flex-start;
                margin: 0 auto 0 0;
                text-align: left;
            }
        }
    }

    .caption {
        padding: 0 0.625rem;
        margin-top: 1.875rem;

        :global {
            .text {
                a {
                    color: $gray-darkest;
                    font-family: $calibre-medium;
                }
            }
        }
    }
}
