@import '../../lib/styles/variables';

.GlossaryControl {
    max-width: 520px;
    padding-right: 1.125rem;
    padding-left: 1.125rem;
    margin: auto;
    margin-bottom: 3.5rem;

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 742px;
        padding-right: 0;
        padding-left: 0;
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        max-width: 992px;
    }

    @media only screen and (min-width: $breakpoint-lg) {
        max-width: 1110px;
    }

    h1 {
        font-family: $calibre-semibold;
        font-size: 1.25rem !important;
    }

    ol {
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 0 !important;
        list-style: none !important;

        li {
            color: $primary;
            font-family: $calibre-medium;
            font-size: 1rem;
            line-height: normal;

            @media only screen and (min-width: $breakpoint-tablet) {
                font-size: 1.5rem;
            }

            a {
                justify-content: center;
                padding: 0.25rem 0.25rem 0;
                color: $primary;
            }

            a.highlightLetter {
                background-color: $secondary;
                color: $white;
            }

            span {
                justify-content: center;
                padding: 0.25rem 0.25rem 0;
                color: $gray-dark2;
            }
        }
    }

    hr {
        border-top: 2px solid $primary;
        margin: 1rem auto 0;
        background-color: transparent;
        color: transparent;
    }

    &__Breadcrumb {
        padding-top: 2rem;
        padding-bottom: 2.25rem;
        color: $primary;
        font-family: $calibre-medium;
        font-size: 1.25rem;
    }
}
