@import 'lib/styles/variables';

.Tests {
    .subtitle {
        color: $brand-green-3;
    }
}

.Plans,
.Tests {
    padding-bottom: 3.75rem;

    .head {
        position: relative;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: space-between;

        .top {
            > a {
                text-decoration: none;
            }
        }
    }

    .title {
        margin-top: 1.5rem;
        margin-bottom: 0.375rem;
        font-weight: 600;
        line-height: 1.4;
    }

    .subtitle {
        margin-bottom: 1.5rem;
    }

    .best {
        position: absolute;
        z-index: 1;
        top: 15px;
        left: -64px;
        display: flex;
        width: 206px;
        height: 32px;
        align-items: center;
        justify-content: center;
        background: linear-gradient(45deg, #009e82 0%, #146464 100%);
        background-color: rgb(0 0 0 / 30%);
        color: $white;
        font-size: 0.875rem;
        font-weight: 500;
        text-align: center;
        transform: rotate(-45deg);

        &--plan {
            top: 15px;
            left: -100px;
            width: 280px;
            height: 55px;
            line-height: 1.2;
        }
    }

    :global {
        .priceWrapper {
            margin-top: 1.25rem;
        }

        .price--original {
            &::after {
                top: 50%;
                left: -4px;
            }
        }

        .price--current {
            font-weight: 600;
        }

        .suffix {
            font-size: 1rem;
        }

        .currency {
            font-weight: 300;
        }

        .CTA__Wrapper {
            .cta {
                width: 100%;
                margin-top: 0.75rem;
            }
        }

        .salesMessage {
            margin: 0.75rem 0 0;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        .food {
            margin-bottom: 1.25rem;
        }

        :global {
            .salesMessage {
                margin: 1.25rem 0 0.5rem;
            }
        }
    }
}
