@import 'lib/styles/variables';
@import 'lib/styles/common';

.viomeNav {
    display: flex;
    height: 80px;
    padding: 0 2.5rem;
    border-bottom: solid 1px $gray-dark;
    background: $primary;
    font-size: 1rem;

    &__wrapper {
        display: flex;
        width: 100%;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: auto;
    }

    &__main {
        display: flex;
        flex-direction: column;
    }

    &__item {
        position: relative;
    }

    &__topLinks {
        display: none;
    }

    &__link {
        display: block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0.75rem;
        color: $white;
        font-family: $calibre-medium;
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 1;

        .click > & {
            &::after {
                display: block;
                width: 10px;
                height: 10px;
                margin-bottom: 0.625rem;
                background: url('https://images.ctfassets.net/qk4l4jfatr3e/1t4PrS5T8V44hKSgsfJV0V/96258a70aca411508357c928cfe163d6/black_pointer.svg');
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                filter: invert(100%);
                transform: rotate(-90deg);
                transition: all .5s ease-in-out;
            }
        }

        &.active {
            .click > & {
                &::after {
                    margin-bottom: 0;
                    transform: rotate(90deg);
                }
            }

            + .viomeNav__dropdown-menu {
                max-height: 2000px;
                margin: .625em 0 1.375em;
            }
        }

        &--dropdown {
            margin-bottom: 0.3125rem;
            font-size: 1.25rem;
        }
    }

    &__dropdown-menu {
        overflow: hidden;
        max-height: 0;
        transition: .2s ease-in-out;
    }

    &__right {
        display: flex;
    }

    &__misc {
        display: flex;
        align-items: center;

        > .viomeNav__link {
            display: none;
        }
    }

    &__cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1.875rem;

        p {
            display: none;
            margin: 0 0 0 0.3125rem;
            color: $primary;
            font-family: $calibre-medium;
            font-size: 1.429rem;
            font-weight: 500;
            line-height: 1;
        }
    }

    &__account {
        display: none;
    }

    &__menu {
        display: inline-block;
        padding: 0;
        border: none;
        font-size: 1rem;
        line-height: 1;

        &__wrapper {
            position: relative;
            width: 13px;
            height: 13px;
            margin: 0 auto 0.1875em;
            cursor: pointer;
        }

        &__bar {
            position: absolute;
            display: block;
            width: 13px;
            height: 3px;
            background-color: $white;
            transition: .4s;

            &:nth-of-type(1) {
                top: 3px;
            }

            &:nth-of-type(2) {
                top: 9px;
            }
        }

        &.active {
            .viomeNav__menu__bar {
                top: 6px;

                &:nth-of-type(1) {
                    transform: rotate(45deg);
                }

                &:nth-of-type(2) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.viomeDropdown {
    position: fixed;
    z-index: 10;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 0;
    background: $primary;
    overflow-y: hidden;
    transition: .5s ease-in-out;

    &.active {
        max-height: 4000px;
        overflow-y: scroll;
    }

    &.hasStickyBanner {
        top: 160px;
    }

    .viomeNav__topLinks {
        display: flex;
        flex-direction: column;
        padding: 2.5rem 1.875rem;
    }

    .viomeNav__item {
        border-bottom: solid 1px $gray-dark;
        margin-bottom: 1.25rem;
    }

    .viomeNav__btn {
        display: block;
        margin-bottom: 1rem;
        color: $primary;
        font-size: 1.25rem;
        line-height: 1;
    }

    p {
        margin: 0 0 1.25rem;
        color: $charcoal;
        font-family: $calibre-medium;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .viomeNav {
        height: 120px;

        &__main {
            height: 100%;
            flex-direction: row;
            padding: 0 1.25rem;
        }

        &__topLinks {
            display: flex;
            height: 100%;
            align-items: center;

            > p, .mobile {
                display: none;
            }
        }

        &__dropdown-menu {
            position: absolute;
            z-index: 10;
            top: 80px;
            left: -18px;
            display: block;
            display: none;
            width: 290px;
            padding: 25px 0 0;
            border: 1px solid $gray-dark;
            border-top: none;
            margin: 0;
            background: $white;
        }

        .product {
            position: initial;

            &__wrapper {
                display: flex;
                width: 1110px;
                justify-content: space-evenly;
                margin: auto;
            }
        }

        &__link {
            height: 100%;
            padding: 0 1.125rem 0.0625rem;
            font-size: 1.25rem;

            .click & {
                position: relative;

                &::after {
                    margin-left: .5rem;
                }
            }

            &.active {
                .product & {
                    .hasStickyBanner & {
                        + .viomeNav__dropdown-menu {
                            top: 160px
                        }
                    }

                    + .viomeNav__dropdown-menu {
                        right: 0;
                        left: 0;
                        width: auto;
                        text-align: center;
                    }
                }

                + .viomeNav__dropdown-menu {
                    position: absolute;
                    z-index: 10;
                    top: 80px;
                    left: -18px;
                    display: block;
                    width: 290px;
                    padding: 25px 0 0;
                    border: 0;
                    margin: 0;
                    background: $primary;
                }
            }

            &--dropdown {
                padding-left: 1.875rem;
                margin-bottom: 1.5625rem;
                font-family: $calibre;
                font-weight: 400;
            }

            &--icon {
                display: flex;
                justify-content: flex-start;

                img {
                    padding: 0 0 0.3125rem 0.625rem;
                }
            }

            &--product {
                max-width: 270px;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
                text-align: center;

                picture {
                    display: flex;
                    flex-basis: 131px;
                    align-items: center;
                    margin: 0 0 0.625rem;
                }

                span {
                    max-width: none;
                    // Using px here to define a set font-size to the nav item rather than relative
                    font-size: 20px;
                }

                strong {
                    font-family: $calibre-medium;
                    font-weight: 500;
                    line-height: 1.33;
                    white-space: nowrap;
                }
            }
        }

        &__right {
            height: 100%;
            flex: 1 0 auto;
            align-items: center;
            justify-content: flex-end;
        }

        &__misc {
            height: 100%;

            > .viomeNav__link {
                display: flex;
            }
        }

        &__account {
            display: flex;
            height: 100%;
            padding: 0 0 0 1rem;
            border-left: solid 1px $gray-dark;
            margin: 0;

            .active {
                + .viomeNav__dropdown-menu {
                    right: 0;
                    width: auto;
                }
            }
        }

        &__menu {
            display: none;
        }

        p {
            &.label {
                align-self: center;
                margin: 0.625rem 0 0;
            }
        }

        :global {
            .priceWrapper {
                display: block;
                margin-top: .5rem;
            }

            .price {
                font-size: 1.125rem;

                &.price--current {
                    color: $secondary;
                }
            }
        }
    }
}
