@import '../../lib/styles/common';
@import './HorizontalScroll.module.scss';

.HorizontalScroll {
    &__container {
        h4 {
            display: block;
            width: auto;
            max-width: 330px;
            margin: 0 auto 2.625rem;
            font-size: 1.875rem;
            text-align: center;
        }
    }

    > .body {
        display: none;
    }

    .HorizontalScroll__items {
        gap: 1.25rem;

        .body {
            width: 100%;
            max-width: 229px;
            height: auto;
            padding: 0;
            background-color: transparent;

            > p {
                line-height: calc(22 / 16);

                &:last-of-type {
                    margin-top: 1.875rem;
                }
            }
        }

        div {
            picture {
                width: 229px;
                margin-bottom: 2.1875rem;
            }
        }

        p {
            font-size: 1rem;
            line-height: 1;
        }

        &.viome-modern__testimonial--no-check {
            ol {
                margin-bottom: 7.5rem;
            }
        }

        ol, ul, li {
            display: flex;
        }

        ol {
            justify-content: flex-start;
            padding: 0;
            margin-bottom: 1rem;

            li {
                margin: 0 0.1875rem;
            }

            picture {
                width: 18px;
            }
        }

        ul {
            justify-content: space-between;
            margin: 0 0 2.1875rem;

            li {
                align-items: center;

                &::before {
                    display: block;
                    margin: 0.1875rem 0.375rem 0 0;
                    content: url('https://images.ctfassets.net/qk4l4jfatr3e/41f5JTNBoCF7NG2VrG67za/68758b3753bc5cc115901072e5c15a64/green-check-thick.svg');
                }
            }
        }
    }

    img {
        width: 100%;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .body {
            ol {
                justify-content: center;
            }

            > p {
                text-align: center;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .HorizontalScroll__items {
            gap: 1.75rem;

            > .body {
                max-width: 260px;
                height: auto;
            }
        }

        .body {
            > div {
                picture {
                    width: 260px;
                }
            }
        }
    }
}

.button {
    @include viomeButton('secondary');

    width: 100%;
    max-width: 370px;
    padding: 0 1.25rem;

    margin: 2.8125rem auto 8.125rem;
    text-align: center;
}
