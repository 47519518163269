@import './Basic.module.scss';

.our-mission {
    position: relative;
    height: 1191px;

    > .body {
        @extend %absolute-x;

        z-index: 1;
        top: 60px;
        padding: 80px 20px 100px;

        background: $primary;
        color: $white;
        text-align: left;

        p {
            font-family: $calibre-medium;
            letter-spacing: -0.46px;
            line-height: 1.3;
        }

        > p {
            &.heading {
                line-height: 1;
            }

            &.heading-2 {
                font-family: $calibre-semibold;
                font-size: 50px;
                letter-spacing: -1.16px;
            }

            &.heading-3 {
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: normal;
                letter-spacing: -0.25px;
            }
        }

        > ul {
            li {
                margin-top: 70px;
            }

            p {
                margin-top: 30px;
                font-size: 17px;
            }
        }
    }

    > picture {
        @extend %absolute-full;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        height: 1241px;

        > .body {
            right: 0;
            width: 700px;
            max-width: none;
            padding: 70px 90px 100px 70px;
            transform: none;

            > p {
                font-size: 20px;

                &.heading-2 {
                    font-size: 70px;
                    letter-spacing: -1.62px;
                }

                &.heading-3 {
                    font-size: 30px;
                    letter-spacing: -0.38px;
                }
            }

            > ul {
                display: flex;
                margin-top: 80px;

                li {
                    flex-basis: 225px;
                    margin: 0;

                    &:last-of-type {
                        max-width: 175px;
                    }

                    + li {
                        margin-left: 90px;
                    }
                }

                p {
                    font-size: 20px;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        max-width: 1110px;
        height: auto;

        > .body {
            width: 1015px;
            padding: 70px 200px 100px 95px;

            > ul {
                margin-top: 90px;

                li {
                    flex-basis: 490px;
                }
            }
        }

        > picture {
            position: static;

            img {
                width: auto;
                height: auto;
            }
        }
    }
}
