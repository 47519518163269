@import 'lib/styles/variables';

.embed {
    max-width: 843px;
    margin: auto;

    :global {
        .klaviyo-form {
            form {
                p,
                label,
                input,
                button {
                    font-family: $font-TWKLausanne !important;
                }

                p,
                label {
                    font-weight: 300 !important;
                }

                input {
                    padding-left: 0.625rem !important;
                    border-top: 0 !important;
                    border-right: 0 !important;
                    border-left: 0 !important;
                }

                button {
                    padding: 0 1.5rem !important;
                    font-weight: 400 !important;
                }

                [data-testid='form-component'] {
                    + [data-testid='form-component'] {
                        button {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }

        .truemed-prequalify-link-text,
        .truemed-instructions-link-text {
            justify-content: flex-start;

            span {
                color: $black;
                font-family: $font-TWKLausanne;
                font-weight: 300;
            }

            > div {
                > div {
                    display: inline-flex;
                }

                img {
                    display: inline;
                }
            }
        }
    }
}
