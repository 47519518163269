@import 'lib/styles/variables';

.Carousel {
    position: relative;
    width: 100%;

    &.align-top {
        :global {
            .swiper-wrapper {
                align-items: flex-start;
            }
        }
    }

    &.full-height {
        :global {
            .swiper-wrapper {
                align-items: stretch;
            }

            .swiper-slide {
                display: flex;
                height: auto;
                align-items: stretch;

                .row > div {
                    height: 100%;
                }
            }
        }
    }

    &.darkText {
        color: $black;
    }

    :global {
        .swiper {
            width: 100%;

            &:not(.swiper-vertical),
            &.swiper-thumbs {
                height: 100%;
            }
        }

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            text-align: center;

            .heroCarousel {
                animation: fadeHeroCarouselInitial 6.4s forwards;
                animation-delay: 1s;
                opacity: 0;
            }
        }

        .swiper-slide-active {
            .heroCarousel__animate {
                animation: fadeHeroCarousel 7.4s forwards;
                animation-delay: 0s;
            }
        }
    }

    &.opaque-inactive {
        :global {
            .swiper-slide {
                &:not(.swiper-slide-active) {
                    opacity: 0.5;
                }
            }
        }
    }

    &.absolute {
        .indicators {
            position: absolute;
            z-index: 1;
            bottom: 40px;
        }
    }

    .indicators {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 2.375rem 0 0;
        gap: 0.125rem;

        &.reset {
            justify-content: inherit;
            margin: 0;
        }

        .indicator {
            display: block;
            width: 24px;
            height: 4px;
            border-radius: 0;
            opacity: 0.2;
        }

        :global {
            .swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    .navigation {
        &.centered {
            display: flex;

            .arrow {
                position: static;
                display: inline-block;
                width: 34px;
                height: 34px;
                opacity: 1;

                &.disabled {
                    display: block;
                    opacity: 0.5;
                }

                + .arrow {
                    margin-left: 1.25rem;
                }

                svg {
                    width: 34px;
                    height: 34px;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                position: absolute;
                bottom: -64px;
                left: calc(50% + 1.5rem);
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                bottom: -34px;
            }

            @media only screen and (min-width: $breakpoint-lg) {
                bottom: 0;
            }
        }

        .arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            display: flex;
            width: 40px;
            height: 40px;
            cursor: pointer;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }

            &.disabled {
                display: none;
            }

            &--prev {
                left: 0;
                transform: scaleX(-1);
            }

            &--next {
                right: 0;
                left: auto;
            }
        }
    }

    &.Default {
        &-IndicatorLeft {
            .indicators {
                justify-content: flex-start;
            }
        }

        &-NavigationTopLeft {
            .navigation {
                display: block;

                .arrow {
                    width: 34px;
                    height: 34px;

                    svg {
                        width: 34px;
                        height: 34px;
                    }

                    &--prev {
                        top: 0;
                        left: 16px;
                    }

                    &--next {
                        top: 0;
                        right: unset;
                        left: 50px;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                .navigation {
                    .arrow {
                        &--prev {
                            top: 8px;
                            left: 40px;
                        }

                        &--next {
                            top: 8px;
                            left: 94px;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                .navigation {
                    .arrow {
                        width: 48px;
                        height: 48px;

                        svg {
                            width: 48px;
                            height: 48px;
                        }

                        &--prev {
                            top: 40px;
                            left: 80px;
                        }

                        &--next {
                            top: 40px;
                            right: unset;
                            left: 144px;
                        }
                    }
                }
            }
        }
    }

    &.Hero-Reversed {
        .indicators {
            position: absolute;
            z-index: 3;
            display: flex;
            width: 100%;
            justify-content: center;
            margin: 0;
            gap: 0.25rem;

            &.hero-centered-right {
                bottom: 4.0625rem;
                left: 50%;
            }

            &.reset {
                width: auto;
            }

            &:not(.reset) {
                bottom: 36px;
            }

            .indicator {
                width: 24px;
                height: 3px;
                background-color: $gray-3 !important;
                opacity: 1;
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                &:not(.reset) {
                    bottom: 100px;
                    width: auto;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                &:not(.reset) {
                    bottom: 150px;
                }
            }

            @media only screen and (min-width: $breakpoint-xl) {
                &:not(.reset) {
                    bottom: 160px;
                }
            }
        }

        &.dark {
            :global {
                .swiper-pagination-bullet-active {
                    background-color: $white !important;
                }
            }
        }

        &.light {
            :global {
                .swiper-pagination-bullet-active {
                    background-color: $black !important;
                }
            }
        }

        :global {
            .swiper-slide {
                > .row {
                    > .row__container {
                        width: 375px;
                        margin: auto;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            .indicators {
                left: calc(50% + 8.5rem);
            }

            :global {
                .swiper-slide {
                    > .row {
                        > .row__container {
                            &:not(.multi) {
                                width: 540px;
                                min-height: 265px;
                                margin-left: 8.5rem;
                            }

                            &.multi {
                                width: 100%;

                                &.flex {
                                    > div {
                                        &:first-child {
                                            width: 50%;
                                            flex: 0 0 50%;

                                            > .row {
                                                display: flex;
                                                height: 100%;
                                                align-items: center;

                                                > .row__container {
                                                    width: 100%;
                                                }
                                            }
                                        }

                                        &:last-child {
                                            > .row {
                                                display: flex;
                                                height: 100%;
                                                align-items: center;

                                                > .row__container {
                                                    width: 65%;
                                                    max-width: 460px;
                                                    margin-left: 8.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-xl) {
            .indicators {
                left: calc(100% - 720px + 8.5rem);
            }

            :global {
                .swiper-slide {
                    > .row {
                        > .row__container {
                            &.multi {
                                &.flex {
                                    > div {
                                        &:first-child {
                                            flex-grow: 1;

                                            > .row {
                                                > .row__container {
                                                    min-height: 265px;
                                                }
                                            }
                                        }

                                        &:last-child {
                                            width: 720px;
                                            flex-basis: 720px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.Gallery,
    &.Solution-Gallery {
        .thumbs {
            display: none;
        }
    }

    &.Gallery,
    &.Solution-Gallery,
    &.hasThumbnails {
        .thumbs {
            :global {
                .swiper-slide {
                    cursor: pointer;
                }
            }
        }
    }

    &.Hero {
        &.hasThumbnails {
            .indicators {
                display: none;
            }
        }

        .thumbs {
            max-width: 1280px;
            margin: 30px auto 0;

            :global {
                .swiper-slide {
                    overflow: hidden;
                    border-radius: 4px;
                    opacity: 0.5;

                    &:hover {
                        opacity: 1;
                    }

                    &:first-of-type {
                        margin-left: 1rem;
                    }

                    > section {
                        aspect-ratio: 186.75 / 120;

                        @supports not (aspect-ratio: 1 / 1) {
                            &::before {
                                padding-top: 64.257%;
                                content: '';
                                float: left;
                            }

                            &::after {
                                display: block;
                                clear: both;
                                content: '';
                            }
                        }
                    }
                }

                .swiper-slide-thumb-active {
                    opacity: 1;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                :global {
                    .swiper-slide {
                        &:first-of-type {
                            margin-left: 2.5rem;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                width: min(1280px, calc(100% - 10rem));
                margin-top: 2.5rem;

                :global {
                    .swiper-slide {
                        &:first-of-type {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .navigation {
            .arrow {
                svg {
                    fill: $black;

                    path {
                        stroke: $white;
                    }
                }
            }
        }
    }

    &.Background,
    &.Gallery,
    &.Hero,
    &.Solution-Gallery,
    &.IPhone,
    &.Basic,
    &.Testimonials,
    &.Card,
    &.Multi {
        .navigation {
            display: flex;

            .arrow {
                position: static;
                opacity: 1;

                &.disabled {
                    display: block;
                    cursor: default;
                    opacity: 0.2;
                }
            }
        }
    }

    &.Basic {
        .navigation {
            justify-content: center;
            margin-bottom: 2.5rem;

            .arrow {
                margin: 0 1.25rem;
            }
        }
    }

    &.Gallery,
    &.Hero,
    &.Solution-Gallery,
    &.IPhone,
    &.Testimonials,
    &.Card,
    &.Multi {
        display: flex;
        flex-direction: column;

        .navigation {
            order: 3;
            padding-top: 0.5rem;
        }
    }

    &.Background,
    &.Gallery,
    &.Hero,
    &.Solution-Gallery,
    &.IPhone,
    &.Testimonials,
    &.Card,
    &.Multi {
        .navigation {
            .arrow {
                margin-left: 2.5rem;
            }

            .count {
                flex-grow: 1;
                color: $gray-4;
                font-size: 0.875rem;
            }
        }

        .indicators {
            z-index: 2;
            justify-content: space-between;
            margin-top: 1rem;
            gap: 0.25rem;

            .indicator {
                width: 100%;
                height: 32px;
                margin: 0;

                background: linear-gradient(
                    180deg,
                    transparent 0%,
                    transparent 46.875%,
                    $black 46.875%,
                    $black 53.125%,
                    transparent 53.125%,
                    transparent 100%
                ) !important;
            }
        }

        &.dark {
            .navigation {
                .count {
                    color: $white;
                }
            }

            .indicators {
                .indicator {
                    background: linear-gradient(
                        180deg,
                        transparent 0%,
                        transparent 46.875%,
                        $white 46.875%,
                        $white 53.125%,
                        transparent 53.125%,
                        transparent 100%
                    ) !important;
                }
            }
        }
    }

    &.Background,
    &.Gallery,
    &.Hero,
    &.Solution-Gallery,
    &.IPhone,
    &.Testimonials,
    &.Multi {
        .navigation {
            padding-right: 2rem;
            padding-left: 2rem;
        }

        .indicators {
            padding: 0 1rem;
        }
    }

    &.Solution-Gallery {
        .navigation {
            padding-right: 1.5rem;
        }

        .indicators {
            padding: 0;
        }
    }

    &.Background {
        position: relative;

        .navigation {
            position: absolute;
            right: 0;
            bottom: 66px;
            left: 0;

            .arrow {
                &.disabled {
                    opacity: 0.6;
                }
            }

            .count {
                z-index: 1;
            }
        }

        .indicators {
            position: absolute;
            z-index: 1;
            bottom: 115px;
        }
    }

    &.IPhone {
        position: relative;

        .main {
            z-index: 2;
            width: 190px;
            border-radius: 1.25rem;
            margin: 0.5625rem auto;
            background-color: $black;
        }

        &::after {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 50%;
            display: block;
            width: 213px;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/54260VVA3CZs9HQu17uxu/6068bb757d1992130e7f9ac3e1a75a63/iPhone_13_Pro.png');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            transform: translateX(-50%);
        }

        .indicators {
            padding: 0;
        }
    }

    &.Testimonials {
        :global {
            .testimonial {
                border: 1px solid $gray-3;

                .types {
                    li {
                        p {
                            font-size: 0.75rem;
                        }
                    }
                }

                .text {
                    * {
                        font-family: $font-TWKLausanne;
                        font-size: 0.875rem;
                        line-height: 1.5;

                        @media only screen and (min-width: $breakpoint-tablet) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }

            .swiper-slide-active {
                margin-left: 1rem;
            }

            .swiper-slide {
                &:last-of-type {
                    padding-right: 1rem;
                    margin-left: 0;
                }
            }
        }
    }

    &.Testimonials,
    &.Multi {
        .indicators {
            padding: 0 1rem;
            margin-top: 3rem;
        }
    }

    &.Multi {
        .indicators {
            margin-top: 1rem;
        }
    }

    &.Card {
        .indicators {
            margin-top: 0;
        }

        .navigation {
            .arrow {
                &--prev,
                &--next {
                    path {
                        stroke: $white;
                    }

                    svg {
                        fill: $black;
                    }
                }
            }
        }
    }

    &.Product-Display {
        :global {
            .swiper-slide {
                &:not(.swiper-slide-active) {
                    > .row {
                        > .row__container {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            img {
                                width: 50% !important;
                                min-width: 50% !important;
                                height: 50% !important;
                                min-height: 50% !important;
                                margin: 0 auto !important;
                                filter: blur(3px);
                            }

                            .moduleText {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 100%;
        margin: auto;

        .navigation {
            display: block;
        }

        &.Gallery,
        &.Solution-Gallery {
            flex-direction: row-reverse;
            gap: 1.25rem;

            .main {
                :global {
                    .swiper-slide {
                        align-items: flex-start;
                    }
                }
            }

            .navigation,
            .indicators {
                display: none;
            }

            .thumbs {
                display: block;
                width: 11%;
                max-width: 64px;
                max-height: 600px;

                :global {
                    .swiper-slide {
                        align-items: flex-start;
                        opacity: 0.3;
                    }

                    .swiper-slide-thumb-active {
                        opacity: 1;

                        .image {
                            border: 2px solid $black;
                        }
                    }
                }
            }
        }

        &.Gallery {
            max-height: 600px;

            .thumbs {
                max-height: 600px;
            }
        }

        &.Solution-Gallery {
            max-height: 584px;

            .main {
                width: 584px;
            }

            .thumbs {
                max-height: 584px;
            }
        }

        &.Background {
            .indicators {
                bottom: 88px;
                padding: 0 2.5rem;
            }

            .navigation {
                bottom: 40px;
                padding-right: 3rem;
                padding-left: 3rem;
            }
        }

        &.IPhone {
            .main {
                width: 225px;
                border-radius: 1.5625rem;
                margin: 0.625rem auto;
            }

            &::after {
                bottom: 80px;
                width: 251px;
            }
        }

        &.Basic {
            .navigation {
                margin-bottom: 0;

                .arrow {
                    position: absolute;
                    margin: 0;
                }
            }
        }

        &.Testimonials {
            :global {
                .swiper-slide-active {
                    margin-left: 2.5rem;
                }

                .swiper-slide {
                    &:last-of-type {
                        padding-right: 2.5rem;
                        margin-left: 0;
                    }
                }
            }
        }

        &.Testimonials,
        &.Multi {
            .indicators {
                padding: 0 2.5rem;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        :global {
            .swiper-slide {
                > .row {
                    justify-content: center;

                    > .row__container {
                        width: 100%;
                    }
                }
            }
        }

        &:not(.full) {
            max-width: 1440px;
        }

        &.Gallery,
        &.Solution-Gallery {
            gap: 2rem;

            .thumbs {
                width: 100%;
            }
        }

        &.Gallery {
            max-height: 512px;

            .thumbs {
                max-width: 72px;
                max-height: 512px;
            }
        }

        &.Solution-Gallery {
            &.legacy {
                max-height: 520px;

                .thumbs {
                    max-width: 72px;
                    max-height: 520px;
                }
            }

            max-height: 731px;

            .main {
                width: 100%;
            }

            .thumbs {
                max-width: 80px;
                max-height: 731px;
            }
        }

        &.Background {
            .indicators {
                bottom: 100px;
                padding: 0 5rem;
                gap: 0.5rem;
            }

            .navigation {
                bottom: 48px;
                padding-right: 5rem;
                padding-left: 5rem;
            }

            &.dark {
                svg {
                    path {
                        stroke: $black;
                    }

                    rect {
                        fill: white;
                    }
                }
            }

            :global {
                .swiper-slide {
                    > .row {
                        justify-content: flex-start;
                    }
                }
            }
        }

        &.IPhone {
            .main {
                width: 315px;
                border-radius: 2.1875rem;
                margin: 0.9375rem auto;
            }

            &::after {
                bottom: 86px;
                width: 352px;
            }

            .indicators,
            .navigation {
                width: 352px;
                margin: 0 auto;
            }

            .indicators {
                margin-top: 1.875rem;
            }

            .navigation {
                padding: 0 1rem;
            }
        }

        &.Testimonials {
            padding: 0 5rem;

            :global {
                .swiper-slide-active {
                    margin-left: 0;
                }

                .swiper-slide {
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }

        &.Testimonials,
        &.Multi {
            .indicators {
                padding: 0;
            }
        }

        &.Testimonials,
        &.Card,
        &.Multi {
            .navigation {
                &.centered {
                    display: flex;

                    .arrow {
                        position: static;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        opacity: 1;

                        &.disabled {
                            display: block;
                            opacity: 0.5;
                        }

                        + .arrow {
                            margin-left: 1.25rem;
                        }

                        svg {
                            width: 34px;
                            height: 34px;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-tablet) {
                        position: absolute;
                        bottom: -64px;
                        left: calc(50% + 1.5rem);
                    }

                    @media only screen and (min-width: $breakpoint-lg-new) {
                        bottom: -34px;
                    }

                    @media only screen and (min-width: $breakpoint-lg) {
                        bottom: 0;
                    }
                }

                .arrow {
                    &--prev,
                    &--next {
                        path {
                            stroke: $white;
                        }

                        svg {
                            fill: $black;
                        }
                    }
                }
            }
        }

        &.Hero {
            .indicators,
            .navigation {
                position: absolute;
                z-index: 2;

                width: 100%;
                padding: 0 5rem;
            }

            .indicators {
                bottom: 104px;

                .indicator {
                    background: linear-gradient(
                        180deg,
                        transparent 0%,
                        transparent 46.875%,
                        $white 46.875%,
                        $white 53.125%,
                        transparent 53.125%,
                        transparent 100%
                    ) !important;
                }
            }

            .navigation {
                bottom: 48px;
                width: 100%;

                .count {
                    width: 100%;
                    flex-grow: 1;
                    color: $white;
                }

                .arrow {
                    &.disabled {
                        svg {
                            fill: $gray-6;

                            rect {
                                stroke: $white;
                            }
                        }
                    }

                    &:not(.disabled) {
                        svg {
                            fill: $white;

                            path {
                                stroke: $black;
                            }
                        }
                    }
                }
            }
        }
    }
}
