@import 'lib/styles/variables';

.shop {
    padding: 0 1rem;

    &.basic {
        padding: 0;
        margin: 2rem 0;

        .grid {
            padding: 0;
        }
    }

    &.variant {
        .sectionTitle {
            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    &.section {
        .product {
            background-color: $gray-1;
        }

        .bottom {
            padding: 0 1.5rem 1.5rem;
        }

        :global {
            .CTA__Wrapper {
                display: flex;
                flex-direction: column-reverse;

                .cta {
                    width: 90px;
                    margin: 1.875rem 0;
                }

                .salesMessage {
                    margin: 0;
                }
            }
        }
    }

    .sectionTitle {
        margin: 1rem 0 2rem 1rem;
        font-size: 2.25rem;
        line-height: 1.2;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        padding: 0;

        .sectionTitle {
            margin: 2.5rem;
            font-size: 3rem;
        }

        .grid {
            padding: 0 2.5rem;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        padding: 0 5rem;

        .sectionTitle {
            margin: 6.25rem 0 2.5rem;
        }

        .grid {
            padding: 0;
        }

        &.section {
            .section-3 {
                .bottom {
                    padding: 0 2.5rem 2.5rem;
                }
            }
        }
    }
}

.filter {
    $stroke-thickness: 1px;
    $stroke-color: $black;
    $size: 1rem;

    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-4;
    margin-bottom: 2.5rem;

    &.active {
        .plusToggle {
            &::before {
                transform: rotate(-90deg);
            }

            &::after {
                opacity: 0;
                transform: rotate(-180deg);
            }
        }

        + .productSelectors {
            max-height: 2000px;
        }
    }

    .plusToggle {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        padding-left: 2rem;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.5;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            display: block;
            width: $stroke-thickness;
            height: $size;
            border-radius: $stroke-thickness;
            margin: auto;
            background: $stroke-color;
            content: '';
            transition: all 0.3s ease;
        }

        &::after {
            left: 0;
            width: $size;
            height: $stroke-thickness;
        }

        &::before {
            left: 8px;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        display: none;
    }
}

.productSelectors {
    display: flex;
    overflow: hidden;
    max-height: 0;
    flex-wrap: wrap;
    transition: 0.7s ease-in-out;

    .productSelector {
        padding: 0.125rem 0.875rem;
        border: 1px solid $gray-3;
        border-radius: 2.5rem;

        .productLabel {
            white-space: nowrap;
        }

        &.active {
            border-color: $black;
            background-color: $black;
            color: $white;
        }
    }

    @media only screen and (max-width: $breakpoint-tablet - 1px) {
        gap: 1.125rem;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-height: none;

        .productSelector {
            .productLabel {
                font-size: 1.125rem;
            }
        }
    }

    @media only screen and (max-width: $breakpoint-lg-new - 1px) and (min-width: $breakpoint-tablet) {
        flex-wrap: nowrap;
        gap: 1rem;
        overflow-x: auto;
        scroll-snap-type: x proximity;
        scrollbar-width: none;

        .productSelector {
            scroll-snap-align: center;
        }

        &::before,
        &::after {
            min-width: 24px;
            content: '';
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        gap: 1.125rem 0.75rem;
    }
}

.product {
    display: flex;
    flex-direction: column;
    font-family: $font-TWKLausanne;

    &:hover {
        .top {
            .bundleDescription {
                opacity: 1;
            }
        }
    }
}

.top {
    position: relative;

    .bundleDescription {
        position: absolute;
        z-index: 2;
        padding: 1rem 1.5rem;
        background: rgb(244 244 245 / 90%);
        inset: 0;
        opacity: 0;
        transition: 0.1s ease-in-out;
    }
}

.bottom {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 1.5rem;

    .title {
        text-decoration: none;

        p {
            font-size: 1.5rem;
            line-height: 1.3;
        }
    }

    .bundleDescription {
        margin-top: 0.5rem;

        p {
            color: $gray-5;
            font-size: 0.875rem;
        }
    }

    :global {
        .CTA__Wrapper {
            margin-top: auto;

            .cta {
                width: 100%;
            }
        }
    }
}

.labels {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 16px;
    display: flex;
    display: block;
    flex-wrap: wrap;
    margin: 1rem -0.25rem 0.75rem;

    p {
        display: inline-block;
        padding: 0.125rem 0.75rem;
        border: 1px solid $black;
        border-radius: 2.5rem;
        margin: 0 0.25rem 0.5rem;
        font-size: 0.75rem;

        &.sale,
        &.green-gradient,
        &.rainbow-gradient,
        &.rainbow-dark {
            border: 0;
        }

        &.sale {
            background-color: $brand-green-2;
            color: $white;
        }

        &.green {
            border-color: $brand-green-2;
            color: $brand-green-2;
        }

        &.blue {
            border-color: $action-blue;
            background-color: $action-blue;
        }

        &.red {
            border-color: $action-red;
            background-color: $action-red;
        }

        &.rainbow {
            border-color: $gray-3;
            background-color: $pastel-yellow;
        }

        &.rainbow-dark {
            background-color: $white;
        }

        &.rainbow,
        &.rainbow-dark {
            span {
                -webkit-background-clip: text;
                -webkit-box-decoration-break: clone;
                -webkit-text-fill-color: transparent;

                @extend %rainbow-gradient;
            }
        }

        &.green-gradient,
        &.rainbow-gradient {
            position: relative;
            z-index: 0;

            &::before {
                position: absolute;
                z-index: -1;
                width: 100%;
                padding: 0.0625rem;

                border-radius: 40px;
                content: '';
                inset: -1px;
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }
        }

        &.green-gradient {
            &::before {
                background: $gradient-green;
            }
        }

        &.rainbow-gradient {
            &::before {
                background: $gradient-rainbow;
            }
        }

        &.rainbow-gradient,
        &.blue,
        &.red,
        &.white {
            color: $white;
        }

        &.white {
            border-color: $white;
        }
    }
}

.detailsWrapper {
    display: flex;
    align-items: baseline;

    > p {
        margin-right: 0.25rem;
        font-size: 1.25rem;
        line-height: 1;
    }

    :global {
        .CTA__Wrapper {
            margin-top: 0.875rem;
        }

        .priceWrapper {
            text-align: end;

            .price {
                line-height: 1.4;
            }
        }

        .price--original {
            font-size: 1.25rem;
        }

        .price--current {
            color: $brand-green-3;
            font-size: 1.5rem;
            font-weight: 600;

            .suffix {
                font-size: 1rem;
            }

            .currency {
                font-size: 0.75rem;
                font-weight: 300;
            }
        }

        .salesMessage {
            border-width: 1px;
            border-style: dashed;
            border-radius: 0;
            margin: 1.5rem 0 0.5rem;
            background-color: $pastel-green;

            p {
                font-weight: 400;
            }
        }

        .taxes {
            display: none !important;
        }
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    .bottom {
        .bundleDescription {
            p {
                font-size: 1rem;
            }
        }
    }

    .detailsWrapper {
        :global {
            .CTA__Wrapper {
                display: flex;
                flex: 0 0 100%;

                .salesMessage {
                    flex: 1 1 auto;
                    margin: 0 1rem 0 0;

                    + .cta {
                        max-width: 220px;
                    }
                }

                .cta {
                    width: 100%;

                    span {
                        white-space: nowrap;
                    }
                }
            }

            .priceWrapper {
                margin-top: 0;
            }

            .price--current {
                .suffix {
                    font-size: 1.125rem;
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-lg-new) {
    .bottom {
        .bundleDescription {
            display: none;
        }
    }
}
