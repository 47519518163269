@import 'lib/styles/variables';

.pageSet {
    h3.pageSet__Heading {
        font-size: 2.125rem;
    }

    ul.pageSet__List {
        margin-left: 0;

        li {
            list-style-type: none;

            a {
                margin-left: 0;
                font-size: 1.125rem;
                text-decoration: none;
            }
        }
    }
}
