@import 'lib/styles/variables';

.popup {
    position: fixed;
    z-index: 90001;
    display: none;
    inset: 0;
    overflow-y: scroll;

    &.active {
        display: block;
    }

    &.Light {
        :global {
            .text {
                * {
                    color: $black;
                }
            }
        }
    }

    &.Dark {
        .close {
            &::before,
            &::after {
                border-color: $white;
            }
        }

        :global {
            .text {
                * {
                    color: $white;
                }
            }
        }
    }

    &.upsell {
        .wrapper {
            padding: 0;
            background-color: $white;
        }
    }

    .wrapper {
        padding: 4.6875rem 1.5rem 0;

        hr {
            margin: 1.25rem 0;
            background-color: $gray-4;
        }
    }

    .close {
        position: fixed;
        top: 15px;
        right: 15px;
        width: 23px;
        height: 23px;
        box-sizing: border-box;
        cursor: pointer;
        transform: rotate(135deg);
        transition: transform 0.3s;

        &::before {
            transform: rotate(90deg);
            transition: width 0.3s;
        }

        &::before,
        &::after {
            position: absolute;
            bottom: 23px / 2 - 1px/2;
            display: block;
            width: 23px;
            height: 0;
            border-bottom: solid 1px $black;
            content: '';
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .wrapper {
            padding: 6.25rem 2.5rem 0;
        }

        .close {
            top: 40px;
            right: 40px;
        }

        &.upsell {
            bottom: unset;
            height: 100vh;
            background-color: rgb(0 0 0 / 80%) !important;

            &.active {
                &.center {
                    display: flex;

                    .wrapper {
                        position: relative;
                        width: 664px;
                        height: min-content;
                        margin: 2.5rem auto;
                        overflow-y: scroll;

                        &::-webkit-scrollbar {
                            width: 0.5rem;
                            -webkit-appearance: none;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $gray-4;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        bottom: unset;
        height: 100vh;
        background-color: rgb(0 0 0 / 80%) !important;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0.5rem;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-4;
        }

        &.active {
            &.center {
                display: flex;

                .wrapper {
                    margin: auto;
                }
            }
        }

        &.upsell {
            bottom: unset;
            height: 100vh;
            background-color: rgb(0 0 0 / 80%) !important;

            &.active {
                &.center {
                    .wrapper {
                        width: 1062px;
                        margin: auto;

                        &::-webkit-scrollbar {
                            width: 0;
                        }
                    }
                }
            }
        }

        .wrapper {
            position: relative;
            width: 760px;
            padding: 5rem;
            margin: 5rem auto;
            background-color: white;
        }

        .close {
            position: absolute;
        }
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        &.upsell {
            &.active {
                &.center {
                    .wrapper {
                        width: 1200px;
                    }
                }
            }
        }
    }
}
