@import 'lib/styles/variables';

.publication {
    margin-bottom: 2rem;

    svg {
        flex-shrink: 0;
    }

    a {
        color: $brand-green-2;
        text-decoration: none !important;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline !important;
        }
    }

    .name {
        color: $brand-green-2;
    }

    p {
        font-size: 1rem;
        line-height: 1.4;
    }

    .publisher {
        p {
            display: inline;
        }

        &__name {
            font-family: $font-tiempos;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        margin-bottom: 2.5rem;

        .name {
            font-size: 1.5rem;
        }

        .publisher {
            p {
                font-size: 1.25rem;
            }
        }
    }
}
