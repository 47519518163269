@import '../../lib/styles/variables';

.viome-terms {
    justify-content: center;
    padding: 1.875rem calc(30 / 414 * 100%) 0;

    > h4 {
        padding-bottom: 3.125rem;               /* 50px */
        font-family: $calibre-light;
        font-size: 2.25rem;
        text-align: center;
    }

    a {
        color: $blue;
    }

    i {
        font-size: 0.875rem;                    /* 14px */
    }

    p {
        display: block;
        min-height: 2.5rem;                     /* 40px */
        padding-bottom: 1.5625rem;              /* 25px */
        font-size: 1.125rem;                    /* 18px */
        line-height: calc(25 / 18);
    }

    ol {
        li {
            padding-left: 0.5rem;
            margin-left: 0.9375rem;
            list-style-type: decimal;
        }

        ul {
            li {
                &::marker {
                    display: none;
                    content: '';
                }
            }
        }

        ol {
            li {
                counter-increment: list;
                list-style-type: upper-roman;

                &::marker {
                    content: counter(list, upper-roman) ') ';
                }

                ol {
                    counter-reset: list;

                    li {
                        list-style-type: lower-alpha;

                        &::marker {
                            content: counter(list, lower-alpha) ') ';
                        }
                    }
                }
            }
        }
    }

    ul {
        li {
            margin-bottom: 0.625rem;

            &::marker {
                display: block;
                height: 22px;
                margin-right: 0.625rem;
                content: '•';
            }
        }
    }

    .heading {
        margin-bottom: 0.625rem;
        font-family: $calibre-semibold;
        line-height: 1;

        + p {
            margin-top: 0;
        }
    }

    .heading-1 {
        font-size: 1.875rem;                /* 30px */
    }

    .heading-2 {
        font-size: 1.5rem;                  /* 24px */
    }

    .heading-3 {
        font-size: 1.25rem;                 /* 20px */
    }

    .heading-4, .heading-5 {
        font-size: 1.125rem;                /* 18px */
        text-decoration: underline;
    }

    .heading-5 {
        font-family: $calibre;
    }

}
