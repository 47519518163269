@import '../../lib/styles/variables';

.Card {
    &.Flip {
        width: 100%;
        height: 50vh;
        background-color: transparent;
        perspective: 1000px;

        .before, .after {
            position: absolute;
            width: 100%;
            height: 100%;
            max-height: none;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
        }

        .before {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .after {
            transform: rotateY(180deg);
        }
    }

    &__wrapper {
        cursor: pointer;

        &.active {
            .after {
                max-height: 2000px;
            }

            .has-image {
                > img {
                    transform: rotate(180deg);
                }
            }

        }

        > div {
            transition: all .2s ease-in-out;
        }

        .Flip & {
            position: relative;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            transition: transform 0.8s;

            &.active {
                transform: rotateY(180deg);
            }
        }
    }

    .after {
        overflow: hidden;
        max-height: 0;
    }

    .has-image {
        display: flex;
        justify-content: space-between;

        > img {
            margin: 0 0.5rem;
            transition: transform .2s ease-in-out;
        }
    }
}
