@import './Carousel.module.scss';

.Carousel {
    padding-top: 6.25rem;

    h4 {
        margin-bottom: .75rem;
        font-family: $calibre;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
    }

    &__wrapper {
        max-width: 360px;
        padding: 0 0 8.75rem;
    }

    :global {
        .carousel {
            .control-dots {
                .dot {
                    background: $gray-dark;

                    &.selected, &.hover {
                        background: $secondary;
                    }
                }
            }
        }

        .inline {
            max-width: 120px;
            margin: auto;
        }

        .image {
            margin: auto;
        }

        p {
            &:not(.heading) {
                margin-bottom: 2.5rem;
                font-size: 1rem;
                line-height: calc(24 / 16);
            }
        }

        picture {
            margin-bottom: 1.875rem;

            img {
                width: 120px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &__wrapper {
            max-width: 450px;
        }
    }
}
