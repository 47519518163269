@import '../../lib/styles/variables';

.CLIA-Privacy {
    max-width: 370px;
    padding: 0 20px 5rem;
    margin: 6.25rem auto 0;

    .body {
        text-align: left;
    }

    .inline {
        img {
            width: 100%;
        }
    }

    p {
        font-family: $calibre-medium;

        &.heading {
            margin: 20px 0 15px;
            font-size: 20px;
        }

        &:not(.heading) {
            color: $font-gray;
            font-size: 17px;
            line-height: 1.18;
        }
    }

    .section {
        + .section {
            margin-top: 5rem;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 420px;
        padding: 0 0 5rem;

        .section {
            .body {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            p {
                &.heading {
                    flex-basis: 100%;
                    order: 0;
                    padding-left: 90px;
                }

                &:not(.heading) {
                    flex-basis: 330px;
                    order: 2;
                }
            }

            .inline {
                flex-basis: 48px;
                order: 1;
                margin-top: -10px;
            }

            + .section {
                margin-top: 90px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        display: flex;
        max-width: 920px;
        justify-content: space-between;

        .section {
            max-width: 420px;
            margin-top: 0;

            + .section {
                margin-top: 0;
            }
        }
    }
}
