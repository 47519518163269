@import '../../lib/styles/variables';

section .GlossaryTermsIndex {
    &__item {
        display: block;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 5rem;

        @media only screen and (min-width: $breakpoint-desktop) {
            display: flex;

            > div {
                flex: 50%;
            }
        }

        h2 {
            padding-right: 1.125rem;
            padding-bottom: 1.25rem;
            padding-left: 1.125rem;
            color: $secondary;
            font-family: $calibre-semibold;
            font-size: 3.25rem;

            @media only screen and (min-width: $breakpoint-tablet) {
                padding-right: 0;
                padding-left: 0;
            }

            @media only screen and (min-width: $breakpoint-desktop) {
                padding: 1.125rem 0 3rem;
            }
        }
    }
}
