@import 'lib/styles/variables';

.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;

    > p {
        margin-right: 24px;
        color: $gray-5;
        font-weight: 500;
    }

    .links {
        display: flex;
        align-items: center;
        margin: 0;
        text-align: center;

        li {
            display: block;
            padding: 0 0.75rem;
            list-style-type: none;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                border: 0;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                line-height: 1.4;
                text-decoration: none;

                &:hover {
                    color: $brand-green-3;
                }

                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &.JumpTo {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid $gray-4;
        gap: 16px;

        .jump {
            font-weight: 300;
        }

        svg {
            height: 16px;
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .jump {
                font-size: 18px;
            }

            a {
                font-size: 20px;
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            flex-direction: row;
            justify-content: flex-start;
            gap: 40px;
        }
    }
}
