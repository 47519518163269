@import 'lib/styles/variables';

@keyframes fadeIn {
    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.priceWrapper {
    position: relative;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;
    opacity: 0;

    .taxes {
        display: block;
        color: $gray-5;
        font-size: 0.75rem;
    }
}

.observationMessage {
    padding: 0.5rem 0;
    margin: 1rem 0 0;

    p {
        font-size: 0.875rem;

        + p {
            margin-top: 0;
        }

        a {
            font-size: 0.875rem;
        }
    }

}

.price {
    position: relative;
    display: inline-block;
    font-size: 1.875rem;
    line-height: 1;

    &--current {
        font-family: $font-TWKLausanne;
    }

    &--original {
        margin-right: 0.5rem;

        &::after {
            position: absolute;
            top: 43%;
            right: -2px;
            left: -4px;
            display: block;
            height: 0.085rem;
            background-color: $red-dark;
            content: '';
            transform: rotate(2deg);
        }
    }

    .suffix {
        font-family: $font-TWKLausanne;
        font-size: 0.6em;
    }

    .currency {
        margin-left: 0.375rem;
        font-size: 0.75rem;
    }
}
