@import 'lib/styles/variables';

.article {
    text-decoration: none !important;

    .container {
        p {
            margin-top: 1.25rem;
            font-size: 1.0625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.29;
            text-decoration: none;

            &.author {
                font-family: $calibre-medium;

                + p {
                    margin-top: 0;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            p {
                font-size: 1.25rem;
                letter-spacing: -0.0444rem;
                line-height: 1.2;
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            :global {
                .image {
                    display: flex;
                    height: 190px;
                    align-items: center;
                    justify-content: center;
                    background-color: $primary;

                    > div {
                        max-height: 100%;

                        > div {
                            display: none !important;
                        }

                        > img {
                            position: relative !important;
                            width: auto !important;
                            min-width: 0 !important;
                            height: auto !important;
                            min-height: 0 !important;
                            max-height: 190px !important;
                        }
                    }
                }
            }

            p {
                margin-top: 2.5rem;
                letter-spacing: -0.0375rem;
                line-height: 1.3;
            }
        }

        @media only screen and (min-width: $breakpoint-xl-new) {
            :global {
                .image {
                    height: 230px;

                    > div {
                        > img {
                            max-height: 230px !important;
                        }
                    }
                }
            }
        }
    }
}
