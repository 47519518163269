@import '../../lib/styles/variables';

.grid {
    > .body {
        max-width: 370px;
        padding: 0 1.25rem 5rem;
        margin: auto;

        p {
            &.heading-2 {
                font-family: $tiempos;
                font-size: 1.875rem;
                font-weight: normal;
                line-height: 1.27;
            }

            &.heading-4 {
                margin-bottom: 2.5rem;
                line-height: 1.13;
            }

            &:not(.heading) {
                font-size: 1.125rem;
                line-height: 1.33;

                + p {
                    margin-top: 1.875rem;
                }
            }
        }
    }

    .gridContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            max-width: 560px;
            padding: 0 0 5rem;
        }
    }

    &--single {
        background-color: $gray;

        &.full {
            padding: 5rem 0 6.25rem 1.25rem;
        }

        > .body {
            padding: 0;
            margin: 0;
        }

        :global {
            .viome__section {
                width: 100%;
                margin: 0;

                &:nth-of-type(odd) {
                    .viome__section__wrapper {
                        justify-content: flex-start !important;
                    }

                    .viome__section__body {
                        padding-right: 1.25rem;

                        + picture {
                            margin: 0 35px 0 0;
                        }
                    }
                }

                + .viome__section {
                    margin-top: 1.875rem;
                }

                .viome__section__wrapper {
                    column-gap: 0;
                }

                .viome__section__body {
                    max-width: 230px;
                    padding: 0;

                    + picture {
                        margin-left: 35px;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            &.full {
                padding: 5.625rem 0 5rem;
            }

            > .body {
                max-width: 590px;
                margin: auto;
            }

            :global {
                .viome__section {
                    max-width: 490px;

                    + .viome__section {
                        margin-top: 0;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &.full {
                padding: 10.3125rem 0;
            }

            > .body {
                max-width: 1110px;
            }

            .gridContainer {
                max-width: 1110px;
                flex-direction: row;
                align-items: flex-start;
                margin: auto;
                column-gap: 4.6875rem;
            }

            :global {
                .viome__section__wrapper {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                    row-gap: 1.5625rem;
                }
            }
        }
    }

    &--two {
        &-lg {

            @media only screen and (min-width: $breakpoint-lg) {
                > .body {
                    max-width: 1110px;

                    p {
                        width: 850px;
                    }
                }

                .gridContainer {
                    max-width: 1110px;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    margin: auto;
                    row-gap: 3.5rem;

                    :global {
                        .Card {
                            + .Card {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &--flip {
        .gridContainer {
            :global {
                .Card {
                    width: 310px;
                    height: 430px;

                    p {
                        color: $white;

                        &.heading-4 {
                            margin-bottom: 0.5rem;
                            font-size: 1.5rem;
                        }

                        &:not(.heading) {
                            font-size: 1rem;
                            line-height: 1.25;

                            + p {
                                margin-top: 1rem;
                            }
                        }
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            color: $secondary-light
                        }
                    }

                    + .Card {
                        margin-top: 3.4375rem;
                    }

                    .Card__before {
                        padding: 2.875rem;
                    }

                    .Card__after {
                        overflow: scroll;
                        padding: 2.1875rem;
                        background-color: $primary;
                    }

                    .Card__trigger {
                        display: flex;
                        height: 33px;
                        align-items: center;
                        font-family: $calibre-semibold;

                        &::after {
                            padding: 0.1875rem 0 0 0.75rem;
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/122cZK8p1VuCg3QY6ru9rk/a579383aee7619b88255d42755250476/group-7-copy-6.svg');
                        }
                    }

                    &:nth-child(1) {
                        .Card__before {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2YQBQ54sIBW9vXAiFgyP03/4daf8f94ece5db6d43726e96defbed15/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__1_.jpg?fm=webp');
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                    }

                    &:nth-child(2) {
                        .Card__before {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/TOfPc8q9amOc58rNOB8Fz/e3d2d5ae4dbccdd8509cbb53a2b5c57b/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash.jpg?fm=webp');
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                    }

                    &:nth-child(3) {
                        .Card__before {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/LgJP8AGXuzHyi7V6JLxH3/7d408de1fe26365e2f720a66e5b5d025/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__4_.jpg?fm=webp');
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                    }

                    &:nth-child(4) {
                        .Card__before {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2VVSLbTVd1drTZn2Oy6J4y/287f481683500ad762835d5206a84e73/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__3_.jpg?fm=webp');
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                    }

                    &:nth-child(5) {
                        .Card__before {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/4jBfZBIC70UdF2oWWjvrYp/e44f809cc4fa7ddff29a07877499ca28/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__2_.jpg?fm=webp');
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-tablet) {
                        width: 528px;
                        height: 330px;

                        .Card__before {
                            padding: 3.125rem 3.75rem;
                        }

                        .Card__after {
                            padding: 2.8125rem;
                        }

                        &:nth-child(1) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/7kiLXWvFmrGZA1YkPooebQ/ebce3de3e3a21bc9554c8fea17362898/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__4_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(2) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6aVhfZyKWEH9rfp72ele1y/216c572a63a6fedf1f54a40995e69565/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__3_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(3) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2ZezT1ogERbUzaWjjuLybt/9d47c084fa3db80600fe9e9d76d0473a/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__1_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(4) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/3IwoGWP83vgL1E1V91wm6S/e1f7b90b5089edacf8151801107e86f6/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__2_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(5) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/4Q43y77Y23h7eGipXbhdMI/bee6d3a8e41cf51d1753c4256cfeebf5/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash.jpg?fm=webp');
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-lg) {
                        &:nth-child(1) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/7cOWjfJXmrVKHAAKkDV4uG/b5c3c78daada45537771f866626d152f/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__3_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(2) {
                            margin-top: 0;

                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/751Ld81z1mp7HtHTe5J8Ls/041e5d38b5371bbe50febfbdef2b6c9f/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash.jpg?fm=webp');
                            }
                        }

                        &:nth-child(3) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2IwUIJcL8R28jdoq78Iss9/8750eb6d7b76396c852557d275d46b6a/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__1_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(4) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/29nFr1MTuHzCtWfFw5W0Vm/771ba14fd030109bce83ac59544f023d/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__2_.jpg?fm=webp');
                            }
                        }

                        &:nth-child(5) {
                            .Card__before {
                                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/4JcUfQ5BhHHMJPY4Iz00jz/7778a5628cae7cd18e06a3bb371ee578/pawel-czerwinski-b-7-ib-fp-72-v-ag-unsplash__4_.jpg?fm=webp');
                            }
                        }
                    }
                }
            }
        }
    }
}
