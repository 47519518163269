@import 'lib/styles/variables';

.compareWrapper {
    width: calc(100% - 1.875rem);
    margin-right: auto;
    margin-left: auto;

    &.popup {
        overflow: scroll;
        width: 100%;
        max-height: calc(100vh - 8.4375rem);

        .grid {
            display: block;

            > * {
                &:nth-child(2) {
                    display: block !important;
                }
            }

            :global {
                .text {
                    max-height: none;
                }
            }
        }
    }

    > p {
        margin: 3.75rem 0 1.25rem;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        width: calc(100% - 5rem);

        &.popup {
            max-height: calc(100vh - 10rem);
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        width: min(1280px, calc(100% - 10rem));

        &.popup {
            max-height: unset;
        }
    }
}

.ui_container,
.productGrid .grid {
    display: grid;
    grid-column-gap: 1.875rem;
    grid-template-columns: repeat(2, calc(50% - 15px));

    &:not(&.plans) {
        > * {
            &:nth-child(2) {
                display: none;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        grid-column-gap: 2.5rem;
        grid-template-columns: repeat(2, calc(50% - 20px));
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        grid-column-gap: 80px;
        grid-template-columns: repeat(2, calc(50% - 40px));

        &:not(&.plans) {
            grid-column-gap: 5rem;
            grid-template-columns: repeat(3, 1fr);

            > * {
                &:nth-child(2) {
                    display: block;
                }
            }
        }
    }
}

.ui_container {
    position: sticky;
    z-index: 90;
    z-index: 9;
    top: 0;
    box-sizing: content-box;
    padding: 1.25rem 1rem;
    background-color: $white;

    &.hasSticky {
        top: 80px;
    }

    .dropdown {
        position: relative;

        &.active {
            .menu {
                border: 1px solid $black;
                border-top: 0;
            }
        }

        p {
            color: $black;
            font-size: 0.875rem;
            font-weight: 300;
        }

        &--wrapper {
            position: relative;

            &:nth-child(2) {
                display: none;
            }
        }

        .cta {
            height: 68px;
            justify-content: space-between;
            padding: 0.9375rem;
            border: 1px solid $black;
        }

        .menu {
            position: absolute;
            right: 0;
            left: 0;
            background-color: $white;

            ul {
                padding: 0.9375rem;
                margin: 0;
            }

            li {
                text-align: left;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        padding: 1.25rem 2.5rem;
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 1280px;
        padding: 1.25rem 5rem;
        margin-right: auto;
        margin-left: auto;
    }
}

.productGrid {
    &.addOns {
        .grid {
            &:not(&.plans) {
                > * {
                    &:last-child {
                        display: block;
                    }

                    @media only screen and (max-width: $breakpoint-lg-new - 1) {
                        &:first-child {
                            display: none;
                        }
                    }
                }
            }
        }

        .head {
            .name {
                margin-bottom: 20px;
            }
        }

        :global {
            .image {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .section {
        margin-bottom: 2.5rem;

        .grid {
            > * {
                padding-bottom: 2.5rem;
                border-bottom: 1px solid $gray-4;
            }

            ul {
                margin: 0;

                &.scoreBullets {
                    li {
                        + li {
                            margin-top: 0.75rem;
                        }
                    }
                }

                &.check,
                &.scoreBullets {
                    li {
                        display: flex;
                        align-items: center;
                        margin-left: 0;
                        list-style: none;

                        &.noFill {
                            svg {
                                fill: none;
                            }
                        }
                    }

                    svg {
                        width: 17px;
                        flex: 0 0 1.0625rem;
                        fill: currentcolor;
                    }

                    svg,
                    img {
                        margin-right: 1.125rem;
                    }

                    img {
                        width: 24px;
                        flex: 0 0 24px;
                    }
                }
            }

            li {
                margin-left: 1.5625rem;
                font-family: $font-TWKLausanne;
                font-size: 1rem;
                line-height: 1.5;

                + li {
                    margin-top: 0.375rem;
                }

                &::marker {
                    font-size: 1rem;
                }
            }
        }

        &:first-of-type,
        &:last-of-type {
            .grid {
                > * {
                    border-bottom: 0;
                }
            }
        }

        &:first-of-type {
            .grid {
                > * {
                    padding-bottom: 0;

                    &.productName {
                        padding-bottom: 1.5rem;
                        border-bottom: 1px solid $gray-4;
                    }
                }
            }
        }
    }

    .br {
        display: block;
        height: 24px;
    }

    .bold {
        font-weight: 600;
    }

    .subtitle {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
    }

    .head {
        &Wrapper {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
        }

        .top {
            text-decoration: none;
        }

        .name,
        .subtitle {
            margin-top: 0.625rem;
        }

        .subtitle {
            margin-bottom: 2.5rem;

            &.test {
                color: $brand-green-3;
            }
        }

        .bottom {
            margin-top: auto;
        }

        :global {
            .showInstallment {
                display: none;
            }
        }
    }

    .recommendations {
        &Wrapper {
            display: flex;
            align-items: center;
            margin-top: 1.5rem;
        }

        .score {
            margin-right: 0.375rem;
            font-weight: 600;
        }

        .text {
            font-size: 1rem;
        }
    }

    .ctaSection {
        :global {
            .CTA__Wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .priceWrapper {
                order: 1;
                margin-top: 1.25rem;

                + .cta {
                    margin-top: 0;
                }
            }

            .showInstallment {
                order: 2;
            }

            .salesMessage {
                display: none;
            }
        }
    }

    :global {
        .priceWrapper {
            + .cta {
                width: 100%;
                margin-top: 1.25rem;
            }

            .price {
                &.price--current {
                    font-weight: $black;

                    .suffix {
                        font-size: 1rem;
                    }
                }

                .currency {
                    color: $black;
                }
            }
        }

        .showInstallment {
            margin-top: 0;
        }

        .CTA__Wrapper {
            .salesMessage {
                margin: 0.75rem 0;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .head {
            .name {
                margin-top: 20px;
            }
        }

        .recommendations {
            .score {
                display: flex;
                width: 60px;
                height: 60px;
                align-items: center;
                justify-content: center;
                border: 1px solid $black;
                margin-right: 1.5rem;
                border-radius: 100%;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        :global {
            .CTA__Wrapper {
                .salesMessage {
                    margin: 1.25rem 0;
                }
            }
        }
    }
}
